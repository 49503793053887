import config_settings from "./config";
// const BASE_URL = 'https://palmtechdevelop.yourinspection.report/public/index.php/api'
let BASE_URL;
let REPORT_SETTING_URL;

const origin = () => {
  let origin = window.location.origin.toLowerCase();
  if (origin.includes("https://")) origin = origin.replace("https://", "");
  else origin = origin.replace("http://", "");
  BASE_URL = config_settings[origin].SERVER_URL;
  REPORT_SETTING_URL = config_settings[origin].REPORT_SETTING_URL;
};
origin();

export const API_CONSTANTS = {
  LOGIN: `${BASE_URL}/login`,
  LOGOUT: `${BASE_URL}/logout`,
  RANDOMSTR: `${BASE_URL}/randomize-string`,
  NEW_INSPECTION_DETAILS: `${BASE_URL}/get-details-new-inspection`,
  SCHEDULE_INSPECTION: `${BASE_URL}/v1/schedule-new-inspection`,
  GET_INSPECTION_LIST: `${BASE_URL}/get-inspection-list`,
  DELETE_INSPECTION: `${BASE_URL}/delete-inspection`, //delete inspection (anil)
  CANCEL_INSPECTION: `${BASE_URL}/cancel-inspection`, //cancel inspection (anil)
  GET_INSPECTION_DETAILS: `${BASE_URL}/get-inspection-detail`,
  GET_INSPECTION_LIST_EXCEL: `${BASE_URL}/get-inspection-list-excel`, //get inspection etails for excel(anil)
  UPDATE_INSPECTION_DETAILS: `${BASE_URL}/v1/update-inspection-details`,
  USER_ACTIVITY_LOG: `${BASE_URL}/get-user-activity-log`,
  GET_INSPECTION_ACTIVITY: `${BASE_URL}/get-inspection-related-activity-log`,
  GET_INSP_NOTIFICATIONS: `${BASE_URL}/get-notifications-data-orderwise`,
  RESEND_EMAIL_NOTIFICATION: `${BASE_URL}/resend-email`,
  EDIT_USER_DETAIL: `${BASE_URL}/edit-user-detail`,
  SEND_TO_CLIENT: `${BASE_URL}/send-to-client`,
  REQUEST_PAYMENT: `${BASE_URL}/request-payment`,
  ADD_CLIENT: `${BASE_URL}/add-client`,
  GET_AGENT: `${BASE_URL}/get-agent-list`,
  ADD_AGENT: `${BASE_URL}/add-new-agent`,
  EDIT_AGENT: `${BASE_URL}/edit-agent`,
  ADD_NEW_AGENT: `${BASE_URL}/add-agent`,
  GET_DASHBOARD_COUNT: `${BASE_URL}/get-dashboard-counts`,
  VIEW_UPCOMMING_INSPECTIONS: `${BASE_URL}/view-upcoming-inspections`,
  GET_INSPECTIONS_DATEWISE: `${BASE_URL}/get-inspection-datewise`,
  GET_FILTERED_INSPECTIONS: `${BASE_URL}/get-filtered-inspection-list`,
  GET_INSPECTIONS_SEARCH_DATA: `${BASE_URL}/get-inspection-list-search-wise`,
  ADD_NEW_CLIENT: `${BASE_URL}/add-client`,
  REMOVE_AGENT: `${BASE_URL}/remove-agent`,
  GET_AGENT_LIST: `${BASE_URL}/get-agent-list`,
  GET_AGENT_DETAILS: `${BASE_URL}/get-agent-details`,
  GET_FILTER_LIST_DETAILLS: `${BASE_URL}/filter-inspection-list-details`,
  SEND_INSPECTION_REPORT: `${BASE_URL}/send-inspection-report`,
  LOGO_DELETE_RS: `${REPORT_SETTING_URL}/remove-company-logo`,
  EDIT_PROPERTY_DETAILS: `${BASE_URL}/edit-property-details`,
  TOKEN_LOGIN: `${BASE_URL}/authenticate-user`,
  OTP_LOGIN: `${BASE_URL}/verify`,
  RESEND_OTP: `${BASE_URL}/resend-otp`,
  SPECTORA_UPLOAD: `${BASE_URL}/send-inspector-spectora-template`,
  REAL_ESTATE_API: `${BASE_URL}/real-state-api`,

  //user-profile (inspector)
  GET_INSPECTOR_DETAILS: `${BASE_URL}/profile`,
  UPDATE_INSPECTOR_DETAILS: `${BASE_URL}/update-user-profile`,
  CHANGE_INSPECTOR_PASSWORD: `${BASE_URL}/change-password`,
  DELETE_PROFILE: `${BASE_URL}/delete-profile-picture`,

  // New/Trial user signup and password reset
  FORGOT_PASSWORD: `${BASE_URL}/remind`,
  RESET_PASSWORD: `${BASE_URL}/reset`,
  SIGNUP: `${BASE_URL}/register`,
  CREATE_PASSWORD: `${BASE_URL}/create-password`,
  GET_INSPECTIONS_COUNT: `${BASE_URL}/get-inspection-count`,

  // Teams
  GET_TEAM_LIST: `${BASE_URL}/get-team-list`,
  GET_ROLE_LIST: `${BASE_URL}/get-role-list`,
  REMOVE_MEMBER: `${BASE_URL}/remove-member`,
  GET_USER_DETAILS: `${BASE_URL}/get-user-details`,
  EDIT_MEMBER: `${BASE_URL}/edit-member`,
  ADD_MEMBER: `${BASE_URL}/add-new-member`,
  UPLOAD_IMAGE: `${BASE_URL}/upload-image`,
  ACTIVATE_MEMBER: `${BASE_URL}/activate-user`, //ACTIVATION TEAM MEMBER(anil)

  //report(inspection details)
  CHANGE_STATUS: `${BASE_URL}/change-order-status`,
  CHANGE_ORDER_STATUS: `${BASE_URL}/v1/change-order-status`,
  SEND_INSPECTION_REPORT_V1: `${BASE_URL}/v1/send-inspection-report`,
  PORCH_PDF_GEN: `${BASE_URL}/porch-pdf-generate`,
  // Services
  GET_SERVICES_LIST: `${BASE_URL}/get-service-list`,
  ADD_SERVICES: `${BASE_URL}/add-service`,
  DELETE_SERVICE: `${BASE_URL}/remove-service`,
  GET_SERVICE_DETAIL: `${BASE_URL}/get-service-detail`,
  EDIT_SERVICE_LIST: `${BASE_URL}/edit-service`,
  GET_TEMPLATE_LIST: `${BASE_URL}/get-templete-list-for-service`,

  // Company profile
  GET_COMPANY_PROFILE: `${BASE_URL}/get-company-profile`,
  EDIT_COMPANY_DETAILS: `${BASE_URL}/edit-company-details`,
  GET_COMPANY_INFO: `${BASE_URL}/get-company-info`,
  UPDATE_COMPANY_INFO: `${BASE_URL}/update-company-info`,

  // Agreements
  GET_AGREEMENTS_LIST: `${BASE_URL}/get-agreement-list`,
  DELETE_AGREEMENT: `${BASE_URL}/delete-agreement`,
  ADD_AGREEMENT: `${BASE_URL}/add-agreement`,
  EDIT_AGREEMENT: `${BASE_URL}/edit-agreement`,
  GET_AGREEMENT_PLACEHOLDERS: `${BASE_URL}/get-agreement-placeholders`,
  GET_NEW_AGREEMENT_NAME: `${BASE_URL}/get-new-agreement-name`,
  UPDATE_AGREEMENT_INFO: `${BASE_URL}/update-agreement-info`,
  // Agreement signature
  UPLOAD_UNSIGNED_AGREEMENT: `${BASE_URL}/upload-unsigned-agreement`,
  UPLOAD_SIGNED_AGREEMENT: `${BASE_URL}/upload-signed-agreement`,
  GET_AGREEMENT_CONTENT: `${BASE_URL}/get-agreement-content`,
  REJECT_AGREEMENT: `${BASE_URL}/reject-agreement`,

  // Notifications center and email templates
  GET_EMAIL_TEMPLATE: `${BASE_URL}/get-email-template`,
  EDIT_EMAIL_TEMPLATE: `${BASE_URL}/edit-email-template`,
  SEND_TEST_EMAIL: `${BASE_URL}/send-test-email`,
  RESET_MAIL_TEMLATE: `${BASE_URL}/reset-default`,
  GET_NOTIFICATION_SETTINGS: `${BASE_URL}/get-notification-settings`,
  SAVE_NOTIFICATION_SETTINGS: `${BASE_URL}/save-notification-settings`,
  RESEND_EMAIL: `${BASE_URL}/resend-email`,

  // Payments
  UPDATE_PAYMENT_INFO: `${BASE_URL}/update-payment-info`,

  //ISG
  STORE_ISG_CREDENTIALS: `${BASE_URL}/store-isg-credentials`,
  EDIT_ISG_CREDENTIALS: `${BASE_URL}/edit-isg-credentials`,
  SEND_ISG_DETAILS: `${BASE_URL}/send-isg-details`,
  //billing
  BILLING_INFO: `${BASE_URL}/get-plan-details`,
  ADD_LICENSES: `${BASE_URL}/add-additional-license`,
  UPGRADE_PLAN: `${BASE_URL}/upgrade-subscription`,
  REMOVE_LICENSE: `${BASE_URL}/remove-additional-license`,
  CANCEL_SUBSCRIPTION: `${BASE_URL}/cancel-subscription`,
  PREVIEW_ALLOCATION: `${BASE_URL}/preview-allocations`,
  PREVIEW_SUBSCRIPTION: `${BASE_URL}/preview-subscription`,
  REACTIVATE_SUBSCRIPTION: `${BASE_URL}/reactivate-subscription`,

  // PT ADMIN USER
  // PT-Company
  GET_PT_COMPANY_LIST: `${BASE_URL}/get-company-list`,
  GET_PT_COMPANY_DETAILS: `${BASE_URL}/get-company-details`,
  ADD_PT_COMPANY: `${BASE_URL}/add-company`,
  EDIT_PT_COMPANY_DETAILS: `${BASE_URL}/edit-pt-company-details`,
  DELETE_PT_COMPANY: `${BASE_URL}/delete-company`,
  // PT-team
  GET_PT_TEAM_LIST: `${BASE_URL}/get-pt-team-list`,
  GET_PT_ROLE_LIST: `${BASE_URL}/get-ptrole-list`,
  ADD_PT_TEAM_MEMBER: `${BASE_URL}/add-pt-team-member`,
  GET_PT_TEAM_DETAILS: `${BASE_URL}/get-pt-team-details`,
  EDIT_PT_TEAM_MEMBER: `${BASE_URL}/edit-pt-team-member`,
  REMOVE_PT_TEAM_MEMBER: `${BASE_URL}/remove-pt-team-member`,
  DELETE_PT_LOGO: `${BASE_URL}/delete-company-logo`,
  // PT-support
  GET_PT_SUPPORT_PROFILE: `${BASE_URL}/get-pt-support-profile`,
  UPDATE_PT_SUPPORT_PROFILE: `${BASE_URL}/update-pt-support-profile`,
  DELETE_PT_SUPPORT_PROFILE: `${BASE_URL}/delete-pt-support-profile-pic`,

  // Templates List and View
  GET_TEMPLATE_LIST_VIEW: `${BASE_URL}/get-template-list`,
  GET_TEMPLATE_DETAILS: `${BASE_URL}/get_default_categories_data`,
  GET_CATEGORIES_LIST: `${BASE_URL}/get-categories-list`,
  GET_TEMPLATE_DETAILS2: `${BASE_URL}/get-details-new-template`,
  POST_NEW_TEMPLATE: `${BASE_URL}/add-template`,
  DELETE_TEMPLATE: `${BASE_URL}/delete-template`,
  TEMPLATE_DETAILS: `${BASE_URL}/get-template-detail`,
  UPDATE_TEMPLATE_DETAILS: `${BASE_URL}/update-template-detail`,
  GET_DEFAULT_RATING_COLOR: `${BASE_URL}/get-default-rating-colors`,
  GET_TEMPLATE_DATA: `${BASE_URL}/get_template_data`,
  UPDATE_TEMPLATE_DATA: `${BASE_URL}/update_template_data`,
  PUBLISH_TEMPLATE: `${BASE_URL}/publish-template`,
  UPLOAD_ATTACHMENTS: `${BASE_URL}/add-template-attachments`,
  EDIT_TEMPLATE: `${BASE_URL}/edit-template`,
  DISCARD_TEMPLATE: `${BASE_URL}/discard-template`,
  UPLOAD_MEDIA: `${BASE_URL}/upload-media`,
  TEMPLATE_LIB_LIST: `${BASE_URL}/template-library-list`,
  IMPORT_TEMP_LIB: `${BASE_URL}/import-template`,
  SEND_CANCEL_SUBSCRIPTION_EMAIL: `${BASE_URL}/send-cancel-subscription-email`,
  DUPLICATE_CATEGORY: `${BASE_URL}/duplicate-category`,
  DUPLICATE_LINE: `${BASE_URL}/duplicate-line`,

  // template editor
  GET_CATEGORY_LIST: `${BASE_URL}/get_template_data`,
  GET_CATEGORY_LINES: `${BASE_URL}/get-category-lines`,
  //inspection-payment
  GET_REQUEST_PAYMENT_DATA: `${BASE_URL}/get-payment-detail`,

  //Calendar API
  GET_CALENDAR_DETAILS: `${BASE_URL}/get-user-schedule`,
  GET_INSPECTOR_LIST: `${BASE_URL}/get-inspector-list`,
  CALENDAR_ADD_EVENT: `${BASE_URL}/add-user-event`,
  CALENDAR_EDIT_EVENT: `${BASE_URL}/edit-user-event`,
  CALENDAR_DELETE_EVENT: `${BASE_URL}/delete-user-event`,

  //Metrics API
  GET_GRAPH_DATA: `${BASE_URL}/get-matrics-data`,
  GET_METRIC_DATA: `${BASE_URL}/get-inspection-details-matrics`,

  // v9-Template import
  IMPORT_V9_TEMPLATE: `${BASE_URL}/import-template-from-legacy-portal`,
  V9_IMPORT_STATUS: `${BASE_URL}/check-import-status`,

  // impersonate User
  IMPERSONATE_USER: `${BASE_URL}/impersonate`,
  STOP_IMPERSONATE: `${BASE_URL}/stop-impersonation`,
  GET_KEY: `${BASE_URL}/get-mapbox-key`,

  //Download link
  DOWNLOAD_LINK: `${BASE_URL}/get-mobile-app-link`,

  //error logs
  GET_ERROR_LIST: `${BASE_URL}/get-error-logs`,
  GET_ERROR_DETAIL: `${BASE_URL}/get-error-log-data`,

  //import
  GET_IMPORT_COMPANY_LIST: `${BASE_URL}/get-companies-for-import`,
  IMPORT_TEMPLATE_FILE: `${BASE_URL}/import-template-file`,
  CANCEL_IMPORT_FILE: `${BASE_URL}/cancel-import`,
  MOVE_TO_CUSTOMER_IMPORT: `${BASE_URL}/move-template-to-customer`,
  SEND_TO_DEV_TEAM: `${BASE_URL}/send-error-email`,
  GET_PUBLISHED_TEMPLATE_LIST: `${BASE_URL}/ptowner/template-list-company-wise`,
  COPY_TEMPLATE: `${BASE_URL}/ptowner/copy-template`,

  //PDF form fill
  PT_TEMPLATE_LIST: `${BASE_URL}/ptowner/get-template-list`,
  PT_UPDATE_TEMPLATE_DETAILS: `${BASE_URL}/ptowner/update-template-details`,
  PT_DELETE_TEMPLATE: `${BASE_URL}/ptowner/delete-template`,
  PT_GET_TEMPLATE_DATA: `${BASE_URL}/ptowner/get-template-data`,
  PT_GET_NEW_TEMPLATE_DETAILS: `${BASE_URL}/ptowner/get-details-new-template`,
  PT_ADD_TEMPLATE: `${BASE_URL}/ptowner/add-template`,
  PT_UPDATE_TEMPLATE_DATA: `${BASE_URL}/ptowner/update-template-data`,
  PT_EDIT_TEMPLATE: `${BASE_URL}/ptowner/edit-template`,
  PT_DISCARD_TEMPLATE: `${BASE_URL}/ptowner/discard-template`,

  // comment library
  GET_CL_TAG_LIST: `${BASE_URL}/get-cl-tag-list`, // get-cl-tag-list
  ADD_TAG_TO_CATEGORY: `${BASE_URL}/add-tags-to-category`, // add-tags-to-category

  //inspector - flagging
  GET_CI_LIST: `${BASE_URL}/inspector-flag`,
  ASSIGN_FLAG_IN_CATEGORY: `${BASE_URL}/assign-inspector-flag-to-category`,
  ASSIGN_FLAG_IN_LINE: `${BASE_URL}/assign-inspector-flag-to-line`,
};

export const API_METHODS = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  UPDATE: "UPDATE",
  PUT: "PUT",
};
