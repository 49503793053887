import React from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import { useMediaQuery } from "react-responsive";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, TextField, Typography } from "@mui/material";
import { FilterHeader } from "../../config/app-constants";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const FilterModal = (props) => {
  const {
    handleClose,
    open,
    FilterType,
    FilterTypeHandler,
    handleApplyFilters,
    handleCancelFilters,
    agentsLists,
    inspList,
    specificDate,
    fromDate,
    toDate,
    handleSpecificDate,
    handleFromDate,
    handleToDate,
    allInspectors,
    allAgents,
    handleChangeInspectors,
    handleChangeAgents,
    handleStatus,
    status,
    payment,
    agreement,
    handlePaid,
    handleAgreement,
  } = props;
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const FilterStatus = [
    {
      status: "Scheduled",

      value: status.Scheduled,
    },
    {
      status: "Draft",

      value: status.Draft,
    },
    {
      status: "Inprogress",

      value: status.Inprogress,
    },
    {
      status: "Complete",

      value: status.Complete,
    },
    {
      status: "Published",

      value: status.Published,
    },
    {
      status: "Cancelled",

      value: status.Cancelled,
    },
  ];

  const FilterPaid = [
    {
      status: "Paid",
      value: payment.Paid,
    },
    {
      status: "Unpaid",
      value: payment.Unpaid,
    },
    {
      status: "Waived",
      value: payment.Waived,
    },
  ];

  const FilterAgreement = [
    {
      status: "Signed",
      value: agreement.Signed,
    },
    {
      status: "Unsigned",
      value: agreement.Unsigned,
    },
    {
      status: "Waived",
      value: agreement.Waived,
    },
  ];

  const useStyles = styled((theme) => ({
    dialogPaper: {
      height: "400px",
    },
    textField: {
      "& p": {
        color: "red",
      },
    },
  }));

  const classes = useStyles();
  const isMobile = useMediaQuery({ query: "(max-width: 550px)" });

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={"inspection-filter-modal-main"}>
      <Box className={"inspection-filter-modal"}>
        <Grid className={"inspection-filter-modal-grid-main"} container spacing={2}>
          <Grid item xs={8}>
            <h3 className={"inspection-filter-modal-title"}>Filter</h3>
          </Grid>
          <Grid item xs={4}>
            <CloseIcon onClick={handleClose} className={"inspection-filter-modal-close-icon"} />
          </Grid>
          <Grid item xs={12}>
            <h3 style={{ paddingLeft: "18px" }} className="inspection-filter-title">
              Date
            </h3>
            <Box
            // sx={{ paddingBottom: "60px" }}
            >
              <Tabs value={FilterType} className="inspections-filter-modal-tabs" onChange={FilterTypeHandler} aria-label="basic tabs example">
                {FilterHeader.map((label, index) => {
                  return (
                    <Tab
                      className="inspections-filter-modal-tab"
                      label={label}
                      style={{
                        color: FilterType === index && "#ffffff",
                        backgroundColor: FilterType === index && "#4282E2",
                      }}
                      {...a11yProps(0)}
                    />
                  );
                })}
              </Tabs>
            </Box>
            <TabPanel value={FilterType} index={0}></TabPanel>
            <TabPanel value={FilterType} index={1}>
              <Grid container spacing={2}>
                <Grid item xs={5.65} style={{ paddingLeft: "7px", marginBottom: "25px" }} className={"inspection-filter-modal-date-range"}>
                  <FormControl variant="standard" fullWidth>
                    <label className="input-form-label" htmlFor="date" id="demo-simple-select-label">
                      Date
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={specificDate}
                        inputFormat="dd-MM-yyyy"
                        className={"inspection-filter-modal-select"}
                        onChange={(newValue) => handleSpecificDate(newValue)}
                        renderInput={(params) => <TextField {...params} onKeyDown={(e) => e.preventDefault()} size="small" />}
                      // error={Boolean(errors.date)}
                      // helperText={errors.date?.message}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={FilterType} index={2}>
              <Grid container spacing={2} className="inspection-filter-modal-margin" style={{ position: "relative", right: "23px" }}>
                <Grid item xs={5.5} style={{ paddingLeft: "0px", marginBottom: "25px" }} className={"inspection-filter-modal-date-range"}>
                  <FormControl variant="standard" fullWidth>
                    <label className="input-form-label" htmlFor="date" id="demo-simple-select-label">
                      From
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={fromDate}
                        inputFormat="dd-MM-yyyy"
                        // maxDate={toDate}
                        className={"inspection-filter-modal-select"}
                        hintText="Check-in"
                        // shouldDisableDate={disablePrevDates(fromDate)}

                        onChange={(newValue) => handleFromDate(newValue)}
                        // onClick={(newValue)=>console.log(newValue)}

                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onKeyDown={(e) => e.preventDefault()}
                            // className="input-textfield"
                            className={classes.textField}
                            error={fromDate === ""}
                            helperText={
                              // sx={{color:"red"}}
                              fromDate > toDate ? "Please choose a correct date range" : true
                            }
                            // sx={
                            //   fromDate > toDate
                            //     ? { Color: "red" }
                            //     : { color:"blue" }
                            // }
                            size="small"
                          />
                        )}
                      // error={Boolean(errors.date)}
                      // helperText={errors.date?.message}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: "37px" }}>
                  <FormControl variant="standard" fullWidth>
                    <label className="input-form-label" htmlFor="date" id="demo-simple-select-label">
                      To
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={toDate}
                        // minDate={fromDate}
                        inputFormat="dd-MM-yyyy"
                        className={"inspection-filter-modal-select"}
                        onChange={(newValue) => handleToDate(newValue)}
                        // shouldDisableDate={disablePrevDates(fromDate)}
                        // if(fromDate>= to_date){console.log("please select correct date")}else{return true}
                        // onClick={()if(to_date >=from_date){console.log("pick currect date")}}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className={classes.textField}
                            size="small"
                            error={fromDate === ""}
                            onKeyDown={(e) => e.preventDefault()}
                            helperText={fromDate > toDate ? "Please choose a correct date range" : true}
                          />
                        )}
                      // error={Boolean(errors.date)}
                      // helperText={errors.date?.message}
                      // onClick={(to_date >=from_date)?{alert("pick currect date")}:{}}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
              </Grid>
            </TabPanel>
          </Grid>
          <Grid container spacing={2} className="inspection-filter-modal-margins">
            <Grid item xs={6}>
              <h3 className="inspection-filter-title">Inspectors</h3>
              <FormControl fullWidth>
                <label className="inspection-filter-modal-label" htmlFor="Inspectors" id="demo-simple-select-label">
                  INSPECTORS
                </label>
                <Autocomplete
                  className="inspection-filter-modal-select custom-color"
                  id="tags-outlined"
                  // classes={classes}
                  options={inspList}
                  filterSelectedOptions
                  multiple
                  size="small"
                  value={allInspectors}
                  getOptionLabel={(option) => option.name}
                  //prevent the selection of single value in multiple time
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(e, value) => handleChangeInspectors(value)}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        // key={option.name}
                        deleteIcon={<CloseIcon />}
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => <TextField {...params} placeholder="Select Inspectors" />}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: "0px", marginLeft: isMobile && "15px" }}>
              <h3 className="inspection-filter-title">Agents</h3>
              <FormControl fullWidth>
                <label className="inspection-filter-modal-label" htmlFor="Inspectors" id="demo-simple-select-label">
                  AGENTS
                </label>

                <Autocomplete
                  className={"inspection-filter-modal-select custom-color"}
                  id="tags-outlined"
                  classes={classes}
                  options={agentsLists}
                  filterSelectedOptions
                  multiple
                  size="small"
                  value={allAgents}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, value) => handleChangeAgents(value)}
                  //prevent the selection of single value in multiple time
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderTags={(value, getTagProps) => value.map((option, index) => <Chip variant="outlined" deleteIcon={<CloseIcon />} label={option.name} {...getTagProps({ index })} />)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // onChange={({ target }) => InspectorChange(target.value)}
                      placeholder="Select Agent"
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container xs={8} spacing={2} sx={{ marginTop: "30px" }} className="inspection-filter-modal-margin">
            <Grid item xs={5}>
              <h3 className="inspection-filter-title">PAYMENT</h3>
              <FormGroup sx={{ display: "inline" }}>
                {FilterPaid.map((filter, index) => {
                  return <FormControlLabel control={<Checkbox defaultChecked={false} checked={filter.value} onChange={handlePaid} name={filter.status} />} label={filter.status} />;
                })}
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <h3 className="inspection-filter-title">AGREEMENT</h3>
              <FormGroup sx={{ display: "inline" }}>
                {FilterAgreement.map((filter, index) => {
                  return <FormControlLabel control={<Checkbox defaultChecked={false} checked={filter.value} onChange={handleAgreement} name={filter.status} />} label={filter.status} />;
                })}
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="inspection-filter-modal-margin" sx={{ marginTop: "30px" }}>
            <Grid item xs={7}>
              <h3 className="inspection-filter-title">STATUS</h3>
              <FormGroup sx={{ display: "inline" }}>
                {FilterStatus.map((filter, index) => {
                  return (
                    <FormControlLabel
                      control={<Checkbox defaultChecked={false} checked={filter.value} onChange={handleStatus} name={filter.status} />}
                      label={filter.status === "Inprogress" ? "In-progress" : filter.status}
                    />
                  );
                })}
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="inspection-filter-modal-buttons">
            <Button variant="outlined" className="inspection-filter-modal-buttons-cancel" onClick={handleCancelFilters}>
              Cancel
            </Button>
            <Button variant="contained" className="inspection-filter-modal-buttons-apply" onClick={handleApplyFilters}>
              Apply
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default FilterModal;
