import React, { useRef } from "react";
import { useState, useEffect } from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { convertFontToSpan } from "../../utils/services";
import FroalaWithoutDropdown from "../htmlEditor/FroalaWithoutDropdown";

const HtmlCommentEditor = (props) => {
  const { editorContent, setEditorContent, update, froalaConfig, toolbarButtonsConfig } = props;

  const [isEdit, setEdit] = useState(false);

  const editorRef = useRef();
  const handleChange = (name, content) => {
    setEdit(true);
    setEditorContent({ innerHTML: content });
  };

  useEffect(() => {
    setEdit(false);
  }, []);

  const auto = () => {
    update("noToast");
    setEdit(false);
  };

  return (
    <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={() => isEdit && auto()}>
      <div className="editorfix">
        <FroalaWithoutDropdown
          showAI={false}
          commentData={editorContent}
          editorData={convertFontToSpan(editorContent)}
          editor={editorRef}
          id={"non-narrative-information"}
          onChange={handleChange}
          froalaConfig={froalaConfig}
          toolbarButtonsConfig={toolbarButtonsConfig}
        />
      </div>
    </ClickAwayListener>
  );
};

export default HtmlCommentEditor;
