import * as Actions from "../action/TemplateAction";

const initialState = {
  templateData: null,
  categoryList: [],
};

const TemplateReducer = (state = initialState, action) => {
  switch (action?.type) {
    case Actions.UPDATE_TE_DATA: {
      return { ...state, templateData: action.payload };
    }
    case Actions.UPDATE_CATEGORY_DATA: {
      return { ...state, categoryList: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default TemplateReducer;
