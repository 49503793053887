import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import EditButton from "../../../common/TemplateUtils/EditButton";
import Checks from "./Checks";
import { Grid } from "@mui/material";
import TemplateAccord from "../../../common/TemplateUtils/TemplateAccord";
import ChecksEdit from "./ChecksEdit";
import { filterObjectsUptoCategory, timestamp } from "../../../utils/services";

const AdditionalSection = (props) => {
  const {
    type,
    prompt,
    values,
    from,
    AllCategoryList,
    setAllCategoryList,
    name,
    item,
    actions,
    HandleCreateSite,
    updateTemplate,
    isChanged,
    activeRename,
    TriggerClick,
    selectedID,
    lineId,
    renId,
    categoryId,
    renamingId,
    innerTemplate,
    checkActiveFocus,
    dragHandleProps,
  } = props;
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.templates)?.categoryList;

  const [expanded, setExpanded] = React.useState("");
  const [accordOpen, setAccordOpen] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [descArr, setDescArr] = React.useState();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : "inspections");
    setAccordOpen(!accordOpen);
  };

  const SelectDescriptions = (name) => {
    if (name === "Additional Input") {
      return "Answer choices. Selected items will appear checked in new inspections by default.";
    }
  };

  const handleOpen = (event) => {
    event.stopPropagation();
  };

  const EditList = (id) => {
    setIsEdit(!isEdit);
    if (isEdit) {
      let lineIndex = null;
      let locationIndex = null;
      let res = descArr.map(({ value, is_selected, uuid, created_at, updated_at, order, is_deleted }) => ({
        value,
        is_selected,
        uuid,
        created_at,
        updated_at,
        order,
        is_deleted,
      }));
      const totalRes = res.filter((val) => {
        return val.value !== "";
      });
      const catList = AllCategoryList?.template?.categories;
      const categoriesDataIndex = catList.findIndex((cat) => cat.category_id === categoryId);
      lineIndex = catList[categoriesDataIndex].lines.findIndex((line) => line.line_id === lineId);
      locationIndex = catList[categoriesDataIndex].lines[lineIndex].line_input_controls.findIndex((info) => info.line_input_control_id === selectedID);
      catList[categoriesDataIndex].lines[lineIndex].line_input_controls[locationIndex].values = totalRes;
      catList[categoriesDataIndex].lines[lineIndex].updated_at = timestamp();
      catList[categoriesDataIndex].lines[lineIndex].line_input_controls[locationIndex].updated_at = timestamp();
      filterObjectsUptoCategory(catList, categoryId, dispatch, categories);
      updateTemplate("noToast");
      setAllCategoryList((prev) => {
        const newCategoryList = { ...AllCategoryList };
        newCategoryList.template.categories = catList;
        newCategoryList.template.categories.updated_at = timestamp();
        return newCategoryList;
      });
    }
  };

  return (
    <>
      {
        <TemplateAccord
          dragHandleProps={dragHandleProps}
          expanded={expanded}
          from={from}
          accordOpen={accordOpen}
          selectedID={selectedID}
          AllCategoryList={AllCategoryList}
          setAllCategoryList={setAllCategoryList}
          HeaderTitle={prompt}
          checkActiveFocus={checkActiveFocus}
          lineId={lineId}
          renId={renId}
          activeRename={activeRename}
          isChanged={isChanged}
          updateTemplate={updateTemplate}
          TriggerClick={TriggerClick}
          item={item}
          renamingId={(id) => renamingId(id)}
          handleChange={(panel) => handleChange(panel)}
          categoryId={categoryId}
          innerTemplate={
            <>
              <Typography sx={{ pb: "30px" }} className="section">
                {SelectDescriptions(type)}
              </Typography>
              {!isEdit ? (
                <Grid className="section__checks">
                  {values.map((label, i) => {
                    return (
                      label.is_deleted !== 1 && (
                        <Checks
                          from={"Subcategory"}
                          labels={label}
                          index={i}
                          lineId={lineId}
                          categoryId={categoryId}
                          selectedID={selectedID}
                          AllCategoryList={AllCategoryList}
                          updateTemplate={updateTemplate}
                        />
                      )
                    );
                  })}
                </Grid>
              ) : (
                <ChecksEdit lineId={lineId} values={values} categoryId={categoryId} setDescArr={setDescArr} selectedID={selectedID} AllCategoryList={AllCategoryList} />
              )}
              <EditButton EditList={() => EditList()} isEdit={isEdit} />
            </>
          }
          actions={actions}
          handleOpen={(event) => handleOpen(event)}
        />
      }
    </>
  );
};

export default AdditionalSection;
