import { useState, useEffect, useRef } from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Autocomplete, Box, Checkbox, Chip, Grid, SvgIcon, Typography } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SearchIcon from "@mui/icons-material/Search";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { arrowDown } from "../../../assets/svgIcons/Svg";
import { convertFontToSpan, timestamp } from "../../utils/services";
import FroalaWithoutDropdown from "../htmlEditor/FroalaWithoutDropdown";

const froalaConfig = {
  imagePaste: false,
  spellCheck: true,
  heightMin: 100,
  heightMax: 330,
  pluginsEnabled: ["wordPaste", "spellChecker", "codeBeautifier", "fontFamily", "fontSize", "colors", "align", "lists"],
};

const toolbarButtonsConfig = {
  moreText: {
    buttons: ["bold", "italic", "underline", "fontFamily", "fontSize", "textColor", "backgroundColor", "alignLeft", "alignCenter", "alignRight"],
    align: "left",
    buttonsVisible: 3,
  },
  moreRich: {
    buttons: ["insertLink", "emoticons", "specialCharacters", "fontAwesome", "formatOLSimple", "formatUL", "outdent", "indent"],
    align: "left",
    buttonsVisible: 0,
  },
  moreMisc: { buttons: ["undo", "redo"], align: "right", buttonsVisible: 2 },
};

const NonNarrativeAddComment = ({
  open,
  setState,
  editComment,
  setEditComment,
  templateId,
  lineUuid,
  prompt,
  recommendation,
  disableCommentBtn,
  setDisableCommentBtn,
  lineName,
  addNewCommentHandle,
  categoryId,
  categoryName,
  lineId,
}) => {
  // const storeData = useSelector((state) => state.CommonReducer);
  const editorRef = useRef();
  const [editorContent, setEditorContent] = useState({ innerHTML: "" });
  const [commentTitle, setCommentTitle] = useState("");
  const [isCopy, setIsCopy] = useState(true);
  const [tagOpen, setTagOpen] = useState(false);
  // const [tag, setTag] = useState(storeData?.clTag !== undefined ? storeData?.clTag : []);
  const [commentTag, setCommentTag] = useState([]);
  // const dispatch = useDispatch();

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, isCommentDialogOpen: false }));
    setEditorContent({ innerHTML: "" });
    setCommentTitle("");
  };
  const handleChange = (name, content) => {
    setEditorContent({
      innerHTML: content.replace(/<([a-zA-Z0-9]+)>\s*<\/\1>\s*/g, ""),
    });
    setIsCopy(false);
  };

  const createNewComment = () => {
    addNewCommentHandle(lineId, commentTitle, editorContent?.innerHTML);
    setState((prevState) => ({ ...prevState, isCommentDialogOpen: false }));
    setEditorContent({ innerHTML: "" });
    setCommentTitle("");
    setIsCopy(true);
  };

  useEffect(() => {
    setEditorContent({ innerHTML: recommendation || "" });
  }, []);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <Dialog className="comment-dialog-non-narrative" open={open} onClose={handleClose} aria-labelledby="category-modal" aria-describedby="category-modal-description">
      <DialogTitle pb={0}>
        <Grid container display={"flex"}>
          <Grid item xs={11} display={"flex"} justifyContent={"flex-start"}>
            <Typography className="non-narrative-comment-title">{`${categoryName ? categoryName + " -" : ""} ${editComment ? "Edit comment" : "Add comment"}`}</Typography>
          </Grid>
          <Grid item xs={1} display={"flex"} justifyContent={"flex-end"} alignItems={"center"} position={"relative"}>
            <div className="close-wrapper-new-category-right">
              <CloseIcon onClick={handleClose} />
            </div>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Tabs
              value={0}
              className="non-narrative-comment-tabs"
              // onChange={handleChange}
              aria-label="basic tabs example">
              <Tab label={editComment ? "Edit comment" : "New comment"} id={`simple-tab-0`} className="non-narrative-comment-tab" aria-controls={`simple-tabpanel-0`} />
            </Tabs>
            <Divider />
            <div
              role="tabpanel"
              // hidden={value !== index}
              // id={`simple-tabpanel-${index}`}
              // aria-labelledby={`simple-tab-${index}`}
              // {...other}
            >
              <Grid item xs={12} my={2}>
                <FormControl variant="standard" className="forms-control">
                  <label className="non-narrative-comment-label" htmlFor="title">
                    Title
                  </label>
                  <TextField id="comment_title" size="small" variant="outlined" placeholder="Comment title" value={commentTitle} onChange={(e) => setCommentTitle(e.target.value)} type="text" />
                </FormControl>
              </Grid>
              {/* <Grid item xs={12}>
                <FormControl variant="standard" className="forms-control">
                  <label className="cl-new-comment-input-form-label" htmlFor="tags">
                    TAGS
                  </label>
                  <Autocomplete
                    className="forms-control-tag custom-color autocompleteCustom"
                    id="tags-outlined"
                    multiple
                    size="small"
                    limitTags={2}
                    options={tag}
                    onClick={() => (!tagOpen ? setTagOpen(true) : setTagOpen(false))}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option?.name}
                    open={tagOpen}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#ccc" },
                        "& .MuiOutlinedInput-notchedOutline": { borderColor: "#ccc" },
                      },
                    }}
                    onChange={(e, value) => setCommentTag(value)}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          deleteIcon={<CloseOutlinedIcon />}
                          label={option?.name}
                          {...getTagProps({ index })}
                          sx={{ padding: "2px 2px 2px 10px !important", fontSize: "10px !important" }}
                        />
                      ))
                    }
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                        <Box
                          sx={{
                            width: "12px",
                            height: "12px",
                            backgroundColor: option?.color,
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          style={{ marginRight: 8 }}
                        />
                        {option?.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder={commentTag?.length === 0 ? "Add tags" : ""}
                        onClick={() => (!tagOpen ? setTagOpen(true) : setTagOpen(false))}
                        onBlur={() => setTagOpen(false)}
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            fontFamily: "Open Sans!important",
                            fontSize: "14px",
                            fontWeight: 400,
                            paddingLeft: commentTag?.length !== 0 ? "0px" : "20px",
                          },
                          endAdornment: (
                            <>
                              <SearchIcon className={`customSearch ${commentTag?.length !== 0 ? "hidden" : ""}`} style={{ color: "#9b9b9b", marginRight: "8px" }} />
                              <SvgIcon className={`customArrowDown ${tagOpen ? "arrowActive" : ""}`} viewBox="" onClick={() => (!tagOpen ? setTagOpen(true) : setTagOpen(false))}>
                                {arrowDown}
                              </SvgIcon>
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid> */}
              <Grid container className="input-controll">
                <Grid item xs={12} sm={12}>
                  <FormControl variant="standard" className="forms-control">
                    <label className="non-narrative-comment-label" htmlFor="Comment">
                      Comment
                    </label>
                    <FroalaWithoutDropdown
                      copy={isCopy}
                      showAI={false}
                      commentData={recommendation}
                      editorData={convertFontToSpan(editorContent)}
                      editor={editorRef}
                      id={"non-narrative-comment"}
                      onChange={handleChange}
                      froalaConfig={froalaConfig}
                      toolbarButtonsConfig={toolbarButtonsConfig}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions className="non-narrative-dialog-actions">
        <Button variant="outlined" className="non-narrative-comment-cancel" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" className="non-narrative-comment-save" disabled={disableCommentBtn || (commentTitle === "" && editorContent?.innerHTML === "")} onClick={() => createNewComment()}>
          {`${editComment ? "Edit comment" : "Add new comment"}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NonNarrativeAddComment;
