import React from 'react'
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, Divider, Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CITable from './CITable';
import { fontWeight } from '@mui/system';


const ColorIndicators = (props) => {
    const { updateTemplate, AllCategoryList, CloseOpenCI, GetColorIndicatorList, openCI, LoadList, actions, type, data, setColorIndicatorList, templateGUID, templateType } = props;
    return (
        <Modal
            open={openCI}
            onClose={() => CloseOpenCI}
            className={"edit-category-outer-modal"}
        >
            <Box
                sx={{ maxWidth: '846px' }}
                className={"edit-category-modal"}
            >
                <Grid container>
                    <Grid item xs={11.5} sx={{ p: "16px" }}>
                        <Typography className='ci_title'>Color indicators</Typography>
                    </Grid>
                    <Grid item xs={0.5}>
                        <CloseIcon onClick={CloseOpenCI} sx={{ mt: 1 }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} sx={{ p: "24px 32px" }}>
                        <CITable updateTemplate={updateTemplate} AllCategoryList={AllCategoryList} GetColorIndicatorList={GetColorIndicatorList} LoadList={LoadList} CloseOpenCI={CloseOpenCI} tableData={data} setColorIndicatorList={setColorIndicatorList} templateGUID={templateGUID} templateType={templateType} />
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}

export default ColorIndicators