import React from "react";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import SettingsCard from "./SettingsCard";
import SvgIcon from "@mui/material/SvgIcon";
import { agreementSvg, billingSvg, teamSvg, companySvg, paymentSvg, serviceSvg, reportSvg, importSvg, isgIcon } from "../../../assets/svgIcons/Svg";

// PALM 10616
// import config_settings from "../../config/config";

// origin
// let ENV;
// let origin = window.location.origin.toLowerCase();
// if (origin.includes("https://")) origin = origin.replace("https://", "");
// else origin = origin.replace("http://", "");
// ENV = config_settings[origin].ENV;
const SettingsTabs = () => {
  const roleId = sessionStorage.getItem("role_id");
  let permission = roleId === "7" ? false : true;
  let ownerPermission = roleId === "5" ? true : false;
  let AdminPermission = roleId === "6" ? true : false;
  return (
    <Box
      sx={{
        width: "100%",
        px: { xs: "0", sm: "20px", md: "80px", lg: "80px" },
      }}
      className="teams-container">
      <Grid item xs={12} className="details-title">
        <span className="team-user-name">Account Settings</span>
        <p style={{ color: "#3B3B3B" }}>Everything related to your account</p>
      </Grid>
      <Grid container spacing={3}>
        {/* company profile card */}
        <SettingsCard
          navigatePath={"/settings/company-profile"}
          title={"Company Profile"}
          description={"Edit your company name, contact information and logo."}
          icon={
            <SvgIcon viewBox="" style={{ height: "37px", width: "37px" }}>
              {companySvg}
            </SvgIcon>
          }
          permission={true}
          id="company_profile"
        />

        {/* payments card */}
        <SettingsCard
          navigatePath={"/settings/payments"}
          title={"Payments"}
          description={"Manage how you get paid. "}
          icon={
            <SvgIcon viewBox="" style={{ height: "34px", width: "42px" }}>
              {paymentSvg}
            </SvgIcon>
          }
          permission={permission}
          id="payments"
        />

        {/* Services and Fees card */}
        <SettingsCard
          navigatePath={"/settings/services"}
          title={"Services and Fees"}
          description={"Add and manage all the services that your company offers, and their price. "}
          icon={
            <SvgIcon viewBox="" style={{ height: "31px", width: "39px" }}>
              {serviceSvg}
            </SvgIcon>
          }
          permission={true}
          id="services_fees"
        />

        {/* Team card */}
        <SettingsCard
          navigatePath={"/settings/team"}
          title={"Team"}
          description={"Manage your inspectors and their permissions. "}
          icon={
            <SvgIcon viewBox="" style={{ height: "36px", width: "42px" }}>
              {teamSvg}
            </SvgIcon>
          }
          permission={permission}
          id="team"
        />

        {/* Agreements card */}
        <SettingsCard
          navigatePath={"/settings/agreements"}
          title={"Agreements"}
          description={"Create and manage your inspection agreement contracts. "}
          icon={
            <SvgIcon viewBox="" style={{ height: "39px", width: "33px" }}>
              {agreementSvg}
            </SvgIcon>
          }
          permission={true}
          id="agreement"
        />

        {/* Billing card */}
        {ownerPermission && (
          <SettingsCard
            navigatePath={"/settings/billing-plan"}
            title={"Billing Plan"}
            description={"Renew and manage your Palmtech subscription and add-ons. "}
            icon={
              <SvgIcon viewBox="" style={{ height: "29px", width: "29px" }}>
                {billingSvg}
              </SvgIcon>
            }
            permission={true}
            id="billing"
          />
        )}

        {/* Report card */}
        {ownerPermission || AdminPermission ? (
          <SettingsCard
            navigatePath={"/settings/report-settings"}
            title={"Report"}
            description={"Customize your HTML and PDF report. "}
            icon={
              <SvgIcon viewBox="" style={{ height: "29px", width: "29px" }}>
                {reportSvg}
              </SvgIcon>
            }
            permission={true}
            id="report"
          />
        ) : null}
        {/* Import card */}
        {ownerPermission || AdminPermission ? (
          <SettingsCard
            navigatePath={"/settings/import"}
            title={"Import"}
            description={"Import your data and templates from previous versions of Palmtech."}
            icon={
              <SvgIcon viewBox="" style={{ height: "29px", width: "29px" }}>
                {importSvg}
              </SvgIcon>
            }
            permission={true}
            id="import"
          />
        ) : null}
        {/* PALM 10616 */}
        {/* {ENV === "PROD" || ENV === "STAGE" ? null : ( */}
        <>
          {ownerPermission || AdminPermission ? (
            <SettingsCard
              navigatePath={"/settings/inspector-services-group"}
              title={"Inspector Services Group"}
              description={"Manage your ISG integration."}
              icon={
                <SvgIcon viewBox="" style={{ height: "35px", width: "35px" }}>
                  {isgIcon}
                </SvgIcon>
              }
              permission={true}
              id="import"
            />
          ) : null}
        </>
        {/* )} */}
      </Grid>
    </Box>
  );
};

export default SettingsTabs;
