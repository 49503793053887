import moment from "moment";
import validator from "validator";
import { Base64 } from "js-base64";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { apiCall } from "../../../utils/action";
import CloseIcon from "@mui/icons-material/Close";
import Preloader from "../../../helpers/Preloader";
import { AppContext } from "../../../App";
import DeleteIcon from "@mui/icons-material/Delete";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useRef, useState, useEffect, useContext } from "react";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import Divider from "@mui/material/Divider";
// styles imports
import "../../../../assets/styles/containers/_companyProfile.scss";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  Avatar,
  Alert,
  TextareaAutosize,
  Box,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CardMedia,
  FormControl,
  FormHelperText,
  Select,
  Modal,
  MenuItem,
  Typography,
  Grid,
  Button,
  InputAdornment,
  IconButton,
  FormControlLabel,
  TextField,
  Checkbox,
  FormGroup,
  Autocomplete,
  Chip,
  Tooltip,
} from "@mui/material";
// component imports
import { subscriptionCancelOptions } from "../../../config/app-constants";
import {
  onKeyPressed,
  formatPhoneNumberDashes,
  formatZip,
} from "../../../utils/services";

const PTCompanyDetailsView = (props) => {
  const fileInput = useRef();
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState("");
  const [company, setCompany] = useState("");
  const [website, setWebsite] = useState("");
  const [contact, setContact] = useState("");
  const [address, setAddress] = useState("");
  const [subType, setSubType] = useState("");
  const [companyID, setCompanyID] = useState();
  const [teamData, setTeamData] = useState([]);
  const [merchantID, setMerchantID] = useState("");
  const { setReload } = useContext(AppContext);
  const [chargifyId, setChargifyId] = useState("");
  const [subscriptionId, setSubscriptionId] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [APIuserName, setAPIuserName] = useState("");
  const [APIpassword, setAPIpassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isPaymentEnable, setIsPaymentEnable] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [subscriptionStatus, setsubscriptionStatus] = useState(1);
  const [subTypeError, setSubTypeError] = useState(false);
  const [numberOfUsers, setNoOfUsers] = useState(2);
  const [showPassword, setShowPassword] = useState(false);
  const [isTrialCompany, setIsTrialCompany] = useState(0);
  const [emailErrorText, setEmailErrorText] = useState("");
  const [deleteControl, setDeleteControl] = useState(false);
  const [subCancelReason, setSubCancelReason] = useState("");
  const [subStartDate, setSubStartDate] = useState(moment());
  const [subCancelDate, setSubCancelDate] = useState(moment());
  const [lastLoginDate, setLastLoginDate] = useState(moment());
  const [subInitCancelDate, setSubInitCancelDate] = useState(moment());
  const [impersonateAlert, setImpersonateAlert] = useState(false);
  const handleCloseimpersonateAlert = () => setImpersonateAlert(false);
  const [billingRenewalDueDate, setBillingRenewalDueDate] = useState();
  const [subscriptionStatusError, setsubscriptionStatusError] = useState(false);
  const [isTrialCompanyError, setIsTrialCompanyError] = useState(false);
  const [lastInspectionCreatedDate, setLastInspectionCreatedDate] =
    useState(false);
  const [billingPlanName, setBillingPlanName] = useState("Standard Plan");
  const [subCancelReasonError, setSubCancelReasonError] = useState(false);
  const [showProfRemoveDialog, setShowProfRemoveDialog] = useState(false);
  const [subscriptionCost, setSubscriptionCost] = useState(0);
  const [impersonateUserData, setImpersonateUserData] = useState({
    userName: "",
    id: "",
  });
  const [reportDeliveryVersion, setReportDeliveryVersion] = useState(1);
  const [reportDeliveryVersionError, setReportDeliveryVersionError] =
    useState(false);
  const [optOut, setOptOut] = useState(0);
  const [optOutError, setOptOutError] = useState();
  const [masterRDPList, setMasterRDPList] = useState([]);
  const [masterOptOutReason, setMasterOptOutReason] = useState([]);
  const [openOptOutModal, setOpenOptOutModal] = useState(false);
  const [optOutReasons, setOptOutReasons] = useState([]);
  const [tempOptOutVal, setTempOptOutVal] = useState([]);
  const [otherReason, setOtherReason] = useState(false);
  const [otherReasonVal, setOtherReasonVal] = useState("");
  const [sendSignalToPorch, setSendSignalToPorch] = useState(false);
  const [lifetimeRevenue, setLifetimeRevenue] = useState(null);
  const [currentMonthInspectionCount, setCurrentMonthInspectionCount] =
    useState(0);
  const [averageInspectionCount, setAverageInspectionCount] = useState(0);
  const [lastThirtyinspectionCount, setLastThirtyinspectionCount] = useState(0);
  const [totalInspectionCount, setTotalInspectionCount] = useState(0);
  const [tester, setTester] = useState(true);
  const [subscription_cancel_reason_dropdown, setDropdownData] = useState([]);
  // session datas
  const uuId = sessionStorage.getItem("user_id");
  const roleId = sessionStorage.getItem("role_id");
  const companyId = sessionStorage.getItem("company_id");
  let permission = roleId === "4" ? false : true;
  let is_from_us = sessionStorage.getItem("is_from_us");
  let navigate = useNavigate();
  const routeChange = (roleId) => {
    roleId === 2 || roleId === 3 || roleId === 4
      ? navigate(`/company`)
      : roleId === 10 || roleId === 11
        ? navigate(`/import`)
        : navigate(`/dashboard`);
    setReload((prev) => !prev);
  };
  const dateFormat = (date) => {
    if (!date) setBillingRenewalDueDate(null);
    else {
      let date_availiable = new Date(date);
      let date_month = date_availiable.getMonth() + 1;
      let date_day_without_0 = date_availiable.getDate();
      let date_day = date_day_without_0.toString().padStart(2, "0");
      let date_year = date_availiable.getFullYear().toString().substr(-2);
      setBillingRenewalDueDate(`${date_month}/${date_day}/${date_year}`);
    }
  };

  const OtherReasonHandler = (e) => {
    const trimmedValue = e.target.value.trim();
    setOtherReasonVal(trimmedValue);
  };

  // get companyy data from api
  const getCompanyDetails = () => {
    setShowLoader(true);
    toast.dismiss();
    const formdata = {
      company_id: companyId,
      get_company_id: props.companyID,
      user_id: uuId,
      role_id: roleId,
    };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          setZip(data.zip);
          setCity(data.city);
          setCountry(data.country);
          setState(data.state);
          setEmail(data.email);
          setCompanyID(data.id);
          setImage(data.avatar);
          setCompany(data.name);
          setContact(data.phone);
          setWebsite(data.website);
          setAddress(data.address_1);
          setTeamData(data.team_members);
          setsubscriptionStatus(data.subscription_status);
          setChargifyId(data.chargify_id);
          setSubscriptionId(data.subscription_id);
          setMerchantID(data.merchant_id);
          setSubType(data.subscription_type);
          setContactPerson(data?.owner_name);
          setLastLoginDate(data?.lastLoginDate);
          setBillingPlanName(data?.billing_plan);
          setAPIuserName(data.rest_api_username);
          setIsTrialCompany(data.is_trial_company);
          dateFormat(data?.billing_renewal_due_date);
          setIsPaymentEnable(data?.is_payment_enable);
          setSubStartDate(data.subscription_start_date);
          setSubCancelDate(data.subscription_cancel_date);
          setNoOfUsers(data.numberOfUsers);
          setSubCancelReason(data.subscription_cancel_reason);
          setAPIpassword(
            data.rest_api_password ? Base64.decode(data.rest_api_password) : ""
          );
          setMasterRDPList(
            response?.data?.master_rdp_list?.filter((rdp) => rdp.id !== 1)
          );
          setMasterOptOutReason(response?.data?.master_opt_out_list);
          setReportDeliveryVersion(data.rdp_id);
          setOptOut(data.rdp_opt_out === null ? 0 : data.rdp_opt_out);
          setOptOutReasons(data.company_opt_out_reason);
          setOtherReason(
            data.rdp_opt_out_other_reason !== null &&
              data.rdp_opt_out_other_reason !== ""
              ? true
              : false
          );
          setOtherReasonVal(data.rdp_opt_out_other_reason);
          setSendSignalToPorch(data?.send_signal_to_porch === 1 ? true : false);
          setLastInspectionCreatedDate(data?.lastInspectionCreatedDate);
          setSubscriptionCost(data?.subscription_cost);
          setLifetimeRevenue(data?.lifetimeRevenue);
          setCurrentMonthInspectionCount(data?.currentMonthInspectionCount);
          setSubInitCancelDate(data?.subscription_initiation_cancel_date);
          setTotalInspectionCount(data?.totalInspectionCount);
          setLastThirtyinspectionCount(data?.lastThirtyinspectionCount);
          setTester(data?.is_tester);
          setShowLoader(false);
          setDropdownData(data?.subscription_cancel_reason_dropdown);
          setAverageInspectionCount(data?.averageMonthlyInspection);
        } else {
          toast.error(message);
        }
      },
      formdata,
      "GET_PT_COMPANY_DETAILS"
    );
  };

  // edit company data
  const editCompanyProfile = () => {
    setShowLoader(true);
    setOpenOptOutModal(false);
    toast.dismiss();
    let formData = new FormData();
    const params = {
      user_id: uuId,
      role_id: roleId,
      zip: !zip ? "" : zip,
      company_id: companyId,
      subscription_status: subscriptionStatus,
      city: !city ? "" : city,
      country: !country ? "" : country,
      merchant_id: merchantID,
      state: !state ? "" : state,
      email: !email ? "" : email,
      subscription_type: subType,
      is_payment_enable: isPaymentEnable ? 1 : 0,
      name: !company ? "" : company,
      phone: !contact ? "" : contact,
      rest_api_username: APIuserName,
      website: !website ? "" : website,
      edit_company_id: props.companyID,
      is_trial_company: isTrialCompany,
      address_1: !address ? "" : address,
      last_login: lastLoginDate || "",
      numberOfUsers: numberOfUsers || 0,
      chargify_id: !chargifyId ? "" : chargifyId,
      subscription_cancel_reason: subCancelReason,
      billing_renewal_due_date: !billingRenewalDueDate
        ? ""
        : billingRenewalDueDate,
      avatar: typeof image === "string" || !image ? "" : image,
      subscription_start_date: !subStartDate ? "" : subStartDate,
      subscription_cancel_date: !subCancelDate ? "" : subCancelDate,
      rest_api_password:
        APIpassword !== "" ? Base64.encode(APIpassword) : APIpassword,
      rdp_id: reportDeliveryVersion,
      rdp_opt_out: optOut,
      company_opt_out_reason:
        optOut == 0 ? "" : optOutReasons?.map((el) => el.id)?.toString(),
      rdp_opt_out_other_reason: otherReason ? otherReasonVal : "",
      setLastInspectionCreatedDate: lastInspectionCreatedDate || "",
      subscription_cost: subscriptionCost || 0,
      lifetimeRevenue: lifetimeRevenue || "",
      currentMonthInspectionCount: currentMonthInspectionCount || 0,
      subscription_initiation_cancel_date: subInitCancelDate || "",
      averageMonthlyInspection: averageInspectionCount || 0,
      lastThirtyinspectionCount: lastThirtyinspectionCount || 0,
      subscription_id: subscriptionId || "",
      totalInspectionCount: totalInspectionCount || 0,
      is_tester: tester,
    };
    Object.keys(params).forEach((key) => formData.append(key, params[key]));
    apiCall(
      (response) => {
        const { message, success } = response.data;
        if (success) {
          toast.success(message);
          setShowLoader(false);
        } else {
          toast.error(message);
          getCompanyDetails();
          setShowLoader(false);
        }
      },
      formData,
      "EDIT_PT_COMPANY_DETAILS"
    );
  };
  // delete member function and API call
  const deleteTeamMember = (id) => {
    setShowLoader(true);
    const formdata = {
      company_id: companyId,
      user_id: uuId,
      role_id: roleId,
      member_id: id,
    };
    handleCloseAlert();
    apiCall(
      (response) => {
        const { message, success } = response.data;
        if (success) {
          toast.success(message);
          setDeleteControl(!deleteControl);
          setShowLoader(false);
        } else {
          toast.error(message);
        }
      },
      formdata,
      "REMOVE_MEMBER"
    );
  };

  // delete profile image
  const deleteProfileImage = () => {
    setShowLoader(true);
    toast.dismiss();
    const formdata = {
      company_id: String(props.companyID),
      user_id: uuId,
      role_id: roleId,
    };
    apiCall(
      (response) => {
        const { message, success } = response.data ? response.data : "";
        if (success) {
          toast.success(message);
          setShowLoader(false);
          setShowProfRemoveDialog(false);
        } else {
          toast.error(message);
          setShowLoader(false);
          setShowProfRemoveDialog(false);
        }
      },
      formdata,
      "DELETE_PT_LOGO"
    );
  };

  //impersonate user API call
  const impersonateUser = () => {
    setShowLoader(true);
    const formdata = {
      company_id: companyId,
      user_id: uuId,
      role_id: roleId,
      persona_id: impersonateUserData.id,
    };
    handleCloseAlert();
    apiCall(
      (response) => {
        const { message, success, data } = response.data;
        if (success) {
          setShowLoader(false);
          sessionStorage.setItem("token", data.token);
          sessionStorage.setItem("impersinated", true);
          sessionStorage.setItem("user_id", data.user_id);
          sessionStorage.setItem("token_id", data.tokenId);
          sessionStorage.setItem("user_name", data.user_name);
          sessionStorage.setItem("last_name", data.last_name);
          sessionStorage.setItem("first_name", data.first_name);
          sessionStorage.setItem("company_id", data.company_id);
          sessionStorage.setItem("is_from_us", data.is_from_us);
          sessionStorage.setItem("role_id", data.role_data.role_id);
          sessionStorage.setItem("total_insp", data.total_inspections);
          sessionStorage.setItem("impersonator_id", data.impersonator.id);
          sessionStorage.setItem("is_trial_company", data.is_trial_company);
          getCompanyInfo(
            data.company_id,
            data.user_id,
            data.role_data.role_id,
            data.role_data.role_id,
            data.role_data.role_display_name
          );
          console.log(data, "data");
        } else {
          setShowLoader(false);
          toast.error(message);
        }
      },
      formdata,
      "IMPERSONATE_USER"
    );
  };

  const getCompanyInfo = (company_id, user_id, role_id, route, role) => {
    const formdata = {
      company_id: company_id,
      user_id: user_id,
      role_id: role_id,
    };
    apiCall(
      (response) => {
        console.log("GET_COMPANY_INFO", response);
        if (response.data.success) {
          sessionStorage.setItem(
            "company_name",
            response.data.data.company_details.name
          );
          sessionStorage.setItem(
            "show_banner",
            response.data.data.company_details.show_banner
          );
          sessionStorage.setItem("is_trial", response.data.is_trial);
          routeChange(route);
          toast.success(`Logged in as ${role}`);
        } else {
          console.log("else", response.data.message);
        }
      },
      formdata,
      "GET_COMPANY_INFO"
    );
  };

  const validate = () => {
    // if (customerType === "") setCustomerTypeError(true);
    // else setCustomerTypeError(false);
    if (subscriptionStatus === "") setsubscriptionStatusError(true);
    else setsubscriptionStatusError(false);
    if (subCancelReason === "") setSubCancelReasonError(true);
    else setSubCancelReasonError(false);
    if (subType === "") setSubTypeError(true);
    else setSubTypeError(false);
    // if (usePorchViaISN === "") setUsePorchViaISNError(true);
    // else setUsePorchViaISNError(false);
    // if (isOneTimePurchase === "") setIsOneTimePurchaseError(true);
    // else setIsOneTimePurchaseError(false);
    if (isTrialCompany === "") setIsTrialCompanyError(true);
    else setIsTrialCompanyError(false);
    if (optOut === "") setOptOutError(true);
    else setOptOutError(false);
    if (reportDeliveryVersion === "") setReportDeliveryVersionError(true);
    else setReportDeliveryVersionError(false);
    if (email === "" || email === null) {
      setEmailError(true);
      setEmailErrorText("Email is required");
    } else if (!validator.isEmail(email)) {
      setEmailError(true);
      setEmailErrorText("Enter correct email format");
    } else if (!/^[A-Z0-9._%+]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setEmailError(true);
      setEmailErrorText("Enter correct email format");
    } else setEmailError(false);
    if (
      email !== "" &&
      email !== null &&
      /^[A-Z0-9._%+]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) &&
      subscriptionStatus !== "" &&
      subCancelReason !== "" &&
      subType !== "" &&
      isTrialCompany !== "" &&
      optOut !== "" &&
      reportDeliveryVersion !== ""
    )
      editCompanyProfile();
  };
  const handleZipChange = (e) => {
    const formattedZip = formatZip(e.target.value);
    setZip(formattedZip);
  };

  const handelOptOutSelections = (checked, value) => {
    setOptOutReasons((prev) => {
      console.log("prev,", prev, value);
      if (checked) {
        if (prev === null || prev?.length === 0) return [value];
        else return [...prev, value];
      } else {
        let filtered = prev.filter((item) => item.id !== value.id);
        return filtered;
      }
    });
  };
  const handleContactChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumberDashes(e.target.value);
    setContact(formattedPhoneNumber);
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleCloseOptOutModal = () => {
    setOptOutReasons(tempOptOutVal);
    setOpenOptOutModal(false);
    setOptOut(0);
    // if((optOutReasons === null || optOutReasons?.length === 0 || optOutReasons === undefined ) || (optOutReasons.length!==0 && (otherReasonVal === '' || otherReasonVal === null))) setOptOut(0)
  };

  const handleOpenimpersonateAlert = (name, id) => {
    setImpersonateAlert(true);
    setImpersonateUserData({ userName: name, id: id });
  };

  const changeOptOutValue = (e) => {
    setOptOut(e.target.value);
    if (e.target.value == 1) {
      setOpenOptOutModal(true);
      setTempOptOutVal(optOutReasons);
      if (reportDeliveryVersion === "") setReportDeliveryVersion(1);
    }
  };
  const handleOtherReason = (e) => {
    if (e.target.checked) setOtherReason(true);
    else {
      setOtherReason(false);
      setOtherReasonVal("");
    }
  };
  const validateOptOut = () => {
    let opterror = document.getElementById("opt_out_error");
    if (
      otherReason === true &&
      (otherReasonVal === null || otherReasonVal === "")
    ) {
      opterror.innerHTML = "Please type in a reason in the text box";
    } else {
      if (
        optOutReasons === null ||
        optOutReasons?.length === 0 ||
        optOutReasons === undefined
      ) {
        if (otherReasonVal === null || otherReasonVal === "") {
          opterror.innerHTML = "Please give atleast one reason";
        } else {
          opterror.innerHTML = "";
          validate();
        }
      } else {
        opterror.innerHTML = "";
        validate();
      }
    }
  };

  const card = (
    <>
      <div className="profile-header">
        <span>Logo</span>
        {permission && (
          <span
            className="edit-section"
            onClick={() => fileInput.current.click()}
          >
            <EditIcon />
            <span>Edit</span>
          </span>
        )}
      </div>
      <input
        ref={fileInput}
        type="file"
        id="profile_image"
        accept="image/x-png,image/gif,image/jpeg"
        onChange={(e) => setImage(e.target.files[0])}
        style={{ display: "none" }}
      />
      {image && (
        <div className="prof-img-wrapper">
          <img
            className="team-prof-img"
            style={{ maxHeight: "320px", maxWidth: "100%" }}
            src={typeof image === "string" ? image : URL.createObjectURL(image)}
            alt="Thumb"
          />
          <p onClick={() => setShowProfRemoveDialog(true)}>
            <DeleteIcon style={{ color: "#E30303" }} />
            <span>Delete photo</span>
          </p>
        </div>
      )}
      {!image && (
        <CardMedia
          component="img"
          className="team-prof-img"
          alt="/broken-image.jpg"
          src={
            "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
          }
        />
      )}
    </>
  );

  const permissionCard = (
    <>
      <Grid container className="company-permission-card">
        <Grid item xs={12}>
          Billing plan
        </Grid>
        <p>{billingPlanName}</p>
        <Grid item xs={12} sx={{ marginTop: "7px" }}>
          <span className="team-permision-text">
            {billingRenewalDueDate ? (
              <>
                Will renew on <br />{" "}
                <span className="date-placeholder">
                  {billingRenewalDueDate}
                </span>
              </>
            ) : (
              "No expiry date."
            )}
          </span>
        </Grid>
        {/* {permission && <Button className="default-btn teams-detail-btn"> Change plan</Button>} */}
      </Grid>
    </>
  );

  const detailsCard = (
    <>
      <Grid container className="team-details-card">
        <Grid
          item
          xs={12}
          className="team-details-cardTitle"
          style={{ marginBottom: "16px" }}
        >
          <span>Contact Info</span>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" className="forms-control">
              <label className="input-form-label" htmlFor="Company">
                Company
              </label>
              <TextField
                type="text"
                id="Company"
                size="small"
                value={company}
                variant="outlined"
                placeholder="Company"
                disabled={!permission}
                className="input-textfield"
                onChange={(e) => setCompany(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" className="forms-control">
              <label className="input-form-label" htmlFor="website">
                Website
              </label>
              <TextField
                type="text"
                id="website"
                size="small"
                value={website}
                variant="outlined"
                placeholder="Website"
                disabled={!permission}
                className="input-textfield"
                onChange={(e) => setWebsite(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="standard"
              className="forms-control field-reset"
            >
              <label className="input-form-label" htmlFor="email_address">
                Email Address*
              </label>
              <TextField
                disabled={!permission}
                id="email_address"
                className="input-textfield "
                size="small"
                variant="outlined"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={emailError}
                helperText={`${emailError ? emailErrorText : ""}`}
                type="email"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" className="forms-control">
              <label className="input-form-label" htmlFor="contact">
                Phone
              </label>
              <TextField
                type="text"
                id="contact"
                size="small"
                value={contact}
                variant="outlined"
                placeholder="Contact"
                disabled={!permission}
                className="input-textfield"
                onChange={(e) => handleContactChange(e)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" className="forms-control">
              <label className="input-form-label" htmlFor="contactPerson">
                Contact
              </label>
              <TextField
                disabled
                type="text"
                size="small"
                placeholder=""
                id="contactPerson"
                variant="outlined"
                value={contactPerson}
                className="input-textfield"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" className="forms-control">
              <label className="input-form-label" htmlFor="Address">
                Address
              </label>
              <TextField
                type="text"
                id="Address"
                size="small"
                value={address}
                variant="outlined"
                placeholder="Address"
                disabled={!permission}
                className="input-textfield"
                onChange={(e) => setAddress(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="standard" className="forms-control">
              <label className="input-form-label" htmlFor="city">
                City
              </label>
              <TextField
                id="city"
                type="text"
                value={city}
                size="small"
                variant="outlined"
                placeholder="City"
                disabled={!permission}
                className="input-textfield"
                onChange={(e) => setCity(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="standard" className="forms-control">
              {Number(is_from_us) === 1 ? (
                <label className="input-form-label" htmlFor="state">
                  State
                </label>
              ) : (
                <label className="input-form-label" htmlFor="state">
                  Province
                </label>
              )}
              <TextField
                type="text"
                id="state"
                size="small"
                value={state}
                variant="outlined"
                disabled={!permission}
                className="input-textfield"
                onChange={(e) => setState(e.target.value)}
                placeholder={Number(is_from_us) === 1 ? "State" : "Province"}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="standard" className="forms-control">
              <label className="input-form-label" htmlFor="country">
                Country
              </label>
              <TextField
                id="city"
                type="text"
                value={country}
                size="small"
                variant="outlined"
                placeholder="Country"
                disabled={!permission}
                className="input-textfield"
                onChange={(e) => setCountry(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="standard" className="forms-control">
              {Number(is_from_us) === 1 ? (
                <label className="input-form-label" htmlFor="zip">
                  Zip Code
                </label>
              ) : (
                <label className="input-form-label" htmlFor="zip">
                  Postal Code
                </label>
              )}
              <TextField
                id="zip"
                value={zip}
                size="small"
                type="number"
                variant="outlined"
                disabled={!permission}
                onKeyDown={onKeyPressed}
                className="input-textfield"
                onChange={(e) => handleZipChange(e)}
                placeholder={Number(is_from_us) ? "Zip" : "Postal"}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  const paymentCard = (
    <Grid
      container
      className="team-details-card"
      style={{ padding: "18px 0px" }}
    >
      <Grid
        item
        xs={12}
        className="team-details-cardTitle"
        style={{ marginBottom: "16px" }}
      >
        <span>Payment Configuration</span>
      </Grid>
      <FormControl variant="standard" className="forms-control">
        <Grid container>
          <Tooltip title={!merchantID ? "Merchant ID is required." : null}>
            <Grid item lg={0.4} md={0.7} sm={1} xs={1}>
              <Checkbox
                size="small"
                disabled={!merchantID}
                id="isPaymentEnable"
                variant="outlined"
                value={isPaymentEnable}
                checked={isPaymentEnable}
                placeholder="Payment Enable"
                className="input-textfield"
                onChange={(e) => setIsPaymentEnable(e.target.checked)}
              />
            </Grid>
          </Tooltip>
          <Grid item xs={11} mt={0.7}>
            <label className="input-form-label" htmlFor="payment">
              Payment Enable
            </label>
          </Grid>
        </Grid>
      </FormControl>
      <Grid item xs={12} sm={12}>
        <FormControl variant="standard" className="forms-control">
          <label className="input-form-label" htmlFor="merchantID">
            Merchant ID number
          </label>
          <TextField
            autoComplete="off"
            type="text"
            size="small"
            id="merchantID"
            variant="outlined"
            value={merchantID}
            disabled={!permission}
            placeholder="Merchant ID"
            className="input-textfield"
            onChange={(e) => setMerchantID(e.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" className="forms-control">
          <label className="input-form-label" htmlFor="restAPIusername">
            API user name
          </label>
          <TextField
            disabled={!permission}
            id="restAPIusername"
            className="input-textfield"
            size="small"
            variant="outlined"
            placeholder="Rest api user name"
            value={APIuserName}
            onChange={(e) => setAPIuserName(e.target.value)}
            type="text"
            autoComplete="off"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" className="forms-control">
          <label className="input-form-label" htmlFor="restAPIpassword">
            API password
          </label>
          <TextField
            disabled={!permission}
            id="restAPIpassword"
            className="input-textfield"
            size="small"
            variant="outlined"
            placeholder="Rest api password"
            value={APIpassword}
            onChange={(e) => setAPIpassword(e.target.value)}
            type={showPassword ? "text" : "password"}
            inputProps={{ autoComplete: "new-password" }}
            InputProps={{
              endAdornment: permission ? (
                <InputAdornment position="end">
                  <IconButton
                    sx={{ mr: "-9px" }}
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ) : (
                ""
              ),
              className: "input_props_field",
              sx: {
                height: { xs: "55px", md: "40px", lg: "40px" },
                fontSize: { xs: "18px", md: "14px", lg: "14px" },
              },
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  );

  const CompanyDetailsCard = (
    <>
      <Grid
        container
        className="team-details-card"
        style={{ padding: "18px 0px" }}
      >
        <Grid
          item
          xs={12}
          className="team-details-cardTitle"
          style={{ marginBottom: "16px" }}
        >
          <span>Additional Info</span>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" className="forms-control">
            <label className="input-form-label" htmlFor="Subscription_ID">
              Subscription ID
            </label>
            <TextField
              id="subscriptionID"
              className="input-textfield"
              size="small"
              variant="outlined"
              placeholder="Subscription ID"
              value={subscriptionId}
              onChange={(e) => setSubscriptionId(e.target.value)}
              type="number"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" className="forms-control">
            <label className="input-form-label" htmlFor="chargify_ID">
              Chargify ID
            </label>
            <TextField
              id="chargifyID"
              className="input-textfield"
              size="small"
              variant="outlined"
              placeholder="Chargify ID"
              value={chargifyId}
              onChange={(e) => setChargifyId(e.target.value)}
              type="number"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" className="forms-control">
            <label className="input-form-label" htmlFor="companyID">
              Company ID
            </label>
            <TextField
              disabled={true}
              id="companyID"
              className="input-textfield"
              size="small"
              variant="outlined"
              placeholder="Company"
              value={companyID}
              onChange={(e) => setCompanyID(e.target.value)}
              type="text"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <label className="input-form-label pl-15 mb-5" htmlFor="Role">
            Tester
          </label>
          <FormControl fullWidth className="forms-control field-reset">
            <Select
              labelId="demo-simple-select-label"
              id="Role"
              size="small"
              displayEmpty
              value={tester}
              onChange={(e) => setTester(e.target.value)}
            >
              <MenuItem value="">Select Options</MenuItem>
              <MenuItem key="Yes" value={1}>
                Yes
              </MenuItem>
              <MenuItem key="No" value={0}>
                No
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <label
            className="input-form-label pl-15 mb-5"
            htmlFor="subsc ription_status"
          >
            Subscription Status
          </label>
          <FormControl fullWidth className="forms-control field-reset">
            <TextField
              id="subscriptionStatus"
              disabled={true}
              className="input-textfield"
              size="small"
              variant="outlined"
              placeholder="Subscription ID"
              value={subscriptionStatus}
              onChange={(e) => setsubscriptionStatus(e.target.value)}
              type="text"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <label
            className="input-form-label pl-15 mb-5"
            htmlFor="sub_type"
            id="subscription_type"
          >
            Subscription Type
          </label>
          <FormControl fullWidth className="forms-control field-reset">
            <Select
              disabled={true}
              labelId="subscription-type"
              id="sub_type"
              size="small"
              value={subType}
              onChange={(e) => setSubType(e.target.value)}
              displayEmpty
              error={subTypeError}
            >
              <MenuItem value={""}>Select subscription Type</MenuItem>
              <MenuItem key={"Trial plan"} value="0">
                Trial plan
              </MenuItem>
              <MenuItem key={"Palmtech Billing Monthly"} value="1">
                Palmtech Billing Monthly
              </MenuItem>
              <MenuItem key={"Palmtech Billing Annual"} value="2">
                Palmtech Billing Annual
              </MenuItem>
              <MenuItem key={"Palmtech Website Bundle"} value="3">
                Palmtech Website Bundle
              </MenuItem>
              <MenuItem key={"50FOR90"} value="4">
                50FOR90
              </MenuItem>
              <MenuItem key={"Other"} value="5">
                Other
              </MenuItem>
            </Select>
            {subTypeError && (
              <FormHelperText style={{ color: "#d32f2f" }}>
                Select an option
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} style={{ marginTop: "2px" }}>
          <FormControl variant="standard" className="forms-control">
            <label
              className="input-form-label"
              htmlFor="sub_start-date"
              id="sub_start-date"
            >
              Subscription Start Date
            </label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                id="sub_start-date"
                disabled={true}
                value={subStartDate || null}
                inputFormat="dd-MM-yyyy"
                minDate={new Date()}
                onChange={(newValue) =>
                  setSubStartDate(moment(newValue).format("YYYY-MM-DD"))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    helperText={
                      subStartDate > subCancelDate
                        ? "Please choose a correct date range"
                        : true
                    }
                    error={subStartDate < subCancelDate ? true : false}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" className="forms-control">
            <label
              className="input-form-label"
              htmlFor="sub_cancel-date"
              id="sub_cancel-date"
            >
              Subscription Cancel Date
            </label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disabled={true}
                id="sub_cancel-date"
                value={subCancelDate}
                inputFormat="dd-MM-yyyy"
                minDate={new Date()}
                onChange={(newValue) =>
                  setSubCancelDate(moment(newValue).format("YYYY-MM-DD"))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    helperText={
                      subStartDate > subCancelDate
                        ? "Please choose a correct date range"
                        : true
                    }
                    error={subStartDate < subCancelDate ? true : false}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" className="forms-control">
            <label
              className="input-form-label"
              htmlFor="sub_init_cancel-date"
              id="sub_init_cancel-date"
            >
              Subscription Initiation Cancel Date
            </label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disabled={true}
                id="sub_init_cancel-date"
                value={subInitCancelDate}
                inputFormat="dd-MM-yyyy"
                minDate={new Date()}
                onChange={(newValue) =>
                  setSubInitCancelDate(moment(newValue).format("YYYY-MM-DD"))
                }
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <label
            className="input-form-label pl-15 mb-5"
            htmlFor="sub_cancel_reason"
            id="sub_cancel"
          >
            Subscription Cancel Reason
          </label>
          <FormControl fullWidth className="forms-control field-reset">
            <Autocomplete
              fullWidth={true}
              className="custom-color"
              disabled={true}
              value={subCancelReason || []}
              multiple={true}
              options={subscription_cancel_reason_dropdown || []}
              getOptionLabel={(option) => option}
              size="small"
              renderTags={(value, getTagProps) => (
                <div className="cancelReason">
                  {value?.length
                    ? value.map((option, index) =>
                      option.length ? (
                        <Chip
                          label={option}
                          variant="outlined"
                          deleteIcon={<CloseIcon />}
                          {...getTagProps({ index })}
                        />
                      ) : null
                    )
                    : null}
                </div>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="sub_cancel_reason"
                  className="autoCompleteCancel"
                  size="small"
                  labelId="subscription-cancel-reason"
                  variant="outlined"
                />
              )}
            />
            {subCancelReasonError && (
              <FormHelperText style={{ color: "#d32f2f" }}>
                Select an option
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <label
            className="input-form-label pl-15 mb-5"
            htmlFor="trial_company"
          >
            Trial Company
          </label>
          <FormControl fullWidth className="forms-control field-reset">
            <Select
              labelId="trial_company"
              id="trial_company"
              size="small"
              displayEmpty
              value={isTrialCompany}
              onChange={(e) => setIsTrialCompany(e.target.value)}
              disabled={!permission}
              error={isTrialCompanyError}
            >
              <MenuItem value="">Select options</MenuItem>
              <MenuItem key="Yes" value="1">
                Yes
              </MenuItem>
              <MenuItem key="No" value="0">
                No
              </MenuItem>
            </Select>
            {isTrialCompanyError && (
              <FormHelperText style={{ color: "#d32f2f" }}>
                Select an option
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" className="forms-control">
            <label
              className="input-form-label"
              htmlFor="last_login_date"
              id="last_login_date"
            >
              Last Login Date
            </label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disabled={true}
                id="last_login_date"
                value={lastLoginDate}
                inputFormat="dd-MM-yyyy"
                onChange={(newValue) =>
                  setLastLoginDate(moment(newValue).format("YYYY-MM-DD"))
                }
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" className="forms-control">
            <label
              className="input-form-label"
              htmlFor="billing_renewal_due_date"
              id="billing_renewal_due_date"
            >
              Next Renewal Date
            </label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disabled={true}
                id="billing_renewal_due_date"
                value={billingRenewalDueDate}
                inputFormat="dd-MM-yyyy"
                minDate={new Date()}
                onChange={(newValue) =>
                  setBillingRenewalDueDate(
                    moment(newValue).format("YYYY-MM-DD")
                  )
                }
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" className="forms-control">
            <label className="input-form-label" htmlFor="subscriptionCost">
              Subscription Cost
            </label>
            <TextField
              disabled={true}
              id="subscriptionCost"
              className="input-textfield"
              size="small"
              variant="outlined"
              placeholder="Company"
              value={subscriptionCost}
              onChange={(e) => setSubscriptionCost(e.target.value)}
              type="text"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" className="forms-control">
            <label className="input-form-label" htmlFor="companyID">
              Lifetime Revenue
            </label>
            <TextField
              disabled={true}
              id="companyID"
              className="input-textfield"
              size="small"
              variant="outlined"
              placeholder="Company"
              value={lifetimeRevenue}
              onChange={(e) => setLifetimeRevenue(e.target.value)}
              type="text"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" className="forms-control">
            <label className="input-form-label" htmlFor="noOfUsers">
              Number of Users
            </label>
            <TextField
              disabled={true}
              id="noOfUsers"
              className="input-textfield"
              size="small"
              variant="outlined"
              placeholder="Company"
              value={numberOfUsers}
              onChange={(e) => setNoOfUsers(e.target.value)}
              type="number"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" className="forms-control">
            <label
              className="input-form-label"
              htmlFor="currentMonthInspectionCount"
            >
              Number of inspections
            </label>
            <TextField
              disabled={true}
              id="currentMonthInspectionCount"
              className="input-textfield"
              size="small"
              variant="outlined"
              placeholder="Number of inspections"
              value={currentMonthInspectionCount}
              onChange={(e) => setCurrentMonthInspectionCount(e.target.value)}
              type="text"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" className="forms-control">
            <label
              className="input-form-label"
              htmlFor="averageInspectionCount"
            >
              Average monthly inspections
            </label>
            <TextField
              disabled={true}
              id="averageInspectionCount"
              className="input-textfield"
              size="small"
              variant="outlined"
              placeholder="Average monthly inspections"
              value={averageInspectionCount}
              onChange={(e) => setAverageInspectionCount(e.target.value)}
              type="text"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" className="forms-control">
            <label
              className="input-form-label"
              htmlFor="last_login_date"
              id="last_login_date"
            >
              Last inspection date
            </label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disabled={true}
                id="last_login_date"
                value={lastInspectionCreatedDate}
                inputFormat="dd-MM-yyyy"
                maxDate={new Date()}
                onChange={(newValue) =>
                  setLastInspectionCreatedDate(
                    moment(newValue).format("YYYY-MM-DD")
                  )
                }
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" className="forms-control">
            <label
              className="input-form-label"
              htmlFor="lastThirtyinspectionCount"
            >
              Number of inspections in last 30 days
            </label>
            <TextField
              disabled={true}
              id="lastThirtyinspectionCount"
              className="input-textfield"
              size="small"
              variant="outlined"
              placeholder="Company"
              value={lastThirtyinspectionCount}
              onChange={(e) => setLastThirtyinspectionCount(e.target.value)}
              type="text"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" className="forms-control">
            <label className="input-form-label" htmlFor="totalInspectionCount">
              Number of Inspections Total
            </label>
            <TextField
              disabled={true}
              id="totalInspectionCount"
              className="input-textfield"
              size="small"
              variant="outlined"
              placeholder="Company"
              value={totalInspectionCount}
              onChange={(e) => setTotalInspectionCount(e.target.value)}
              type="text"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
        <Divider style={{ width: "100%" }} />
        <Grid
          item
          xs={12}
          className="team-details-cardTitle"
          style={{ marginBottom: "16px", marginTop: "16px" }}
        >
          <span>Report Delivery Page</span>
        </Grid>
        <Grid item xs={12} sm={6}>
          <label className="input-form-label pl-15 mb-5" htmlFor="rdp">
            Report Delivery Page version
          </label>
          <FormControl fullWidth className="forms-control field-reset">
            <Select
              labelId="rdp"
              id="rdp"
              size="small"
              displayEmpty
              value={reportDeliveryVersion}
              onChange={(e) => setReportDeliveryVersion(e.target.value)}
              disabled={!permission || optOut == 1 || !sendSignalToPorch}
              error={reportDeliveryVersionError}
            >
              <MenuItem value="">Select options</MenuItem>
              {masterRDPList?.map((rdplist, index) => (
                <MenuItem key={index} value={rdplist?.id}>
                  {rdplist.page_name}
                </MenuItem>
              ))}
            </Select>
            {reportDeliveryVersionError && (
              <FormHelperText style={{ color: "#d32f2f" }}>
                Select an option
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <label className="input-form-label pl-15 mb-5" htmlFor="opt_out">
            Opt out
          </label>
          <FormControl fullWidth className="forms-control field-reset">
            <Select
              labelId="opt_out"
              id="opt_out"
              size="small"
              displayEmpty
              value={optOut}
              onChange={(e) => changeOptOutValue(e)}
              disabled={!permission || !sendSignalToPorch}
              error={optOutError}
            >
              <MenuItem value="">Select options</MenuItem>
              <MenuItem key="Yes" value="1">
                Yes
              </MenuItem>
              <MenuItem key="No" value="0">
                No
              </MenuItem>
            </Select>
            {optOutError && (
              <FormHelperText style={{ color: "#d32f2f" }}>
                Select an option
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          {optOut == 1 &&
            ((optOutReasons !== null &&
              optOutReasons?.length !== 0 &&
              optOutReasons !== undefined) ||
              (otherReasonVal !== null &&
                otherReasonVal !== "" &&
                otherReasonVal !== undefined)) && (
              <FormControl variant="standard" className="forms-control">
                <label className="input-form-label" htmlFor="Subscription_ID">
                  opt out reasons
                </label>
                <ul>
                  {optOutReasons?.map((item) => (
                    <li className="optout_list">{item.opt_out_reason}</li>
                  ))}
                  {otherReasonVal !== null &&
                    otherReasonVal !== "" &&
                    otherReasonVal !== undefined && (
                      <li className="optout_list">
                        <b>Other Reason :</b> {otherReasonVal}
                      </li>
                    )}
                </ul>
              </FormControl>
            )}
        </Grid>
        {permission && (
          <Grid item xs={12} sm={6} className="team-detail-cta">
            <Button
              onClick={validate}
              className="default-btn teams-detail-btn"
              id="submit_data"
            >
              Update
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );

  const teamsCard = (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          className="profile-details-cardTitle"
          style={{ marginBottom: "16px" }}
        >
          <span>Team</span>
        </Grid>
        <hr className="bottom-border" />
        {teamData?.length !== 0 ? (
          teamData?.map((teamlist, key) => {
            return (
              <>
                <Grid
                  key={key}
                  container
                  className="profile-details-cardDetail p-15"
                >
                  <Grid
                    item
                    xs={2}
                    sm={2}
                    lg={1}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Avatar
                      alt="Remy Sharp"
                      className="teamlist-avatar"
                      src={teamlist.avatar}
                    />
                  </Grid>
                  <Grid item xs={8} sm={7} lg={9} className="team-avatar">
                    <Grid container>
                      <Grid item xs={12} className="member-row">
                        <div className="member-name flex-center team-role">
                          {`${teamlist.first_name} ${teamlist.last_name}`}
                          {teamlist.role_name.includes("Owner") ||
                            teamlist.role_name.includes("Admin") ? (
                            <VerifiedUserIcon
                              className="owner-icon"
                              style={{ marginLeft: "10px" }}
                            />
                          ) : (
                            <BusinessCenterIcon
                              className="insp-icon"
                              style={{ marginLeft: "10px" }}
                            />
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <span className="member-detail">{teamlist.email}</span>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={5}
                    lg={2}
                    sx={{ mt: -2 }}
                    className="center"
                  >
                    <Button
                      startIcon={<GroupOutlinedIcon />}
                      className="login_button"
                      sx={{ height: 34, width: 178, fontSize: 13 }}
                      size="small"
                      variant="contained"
                      onClick={() =>
                        handleOpenimpersonateAlert(
                          `${teamlist.first_name} ${teamlist.last_name}`,
                          teamlist.id
                        )
                      }
                    >
                      Impersonate
                    </Button>
                  </Grid>
                </Grid>
                <hr className="bottom-border" />
              </>
            );
          })
        ) : (
          <Grid container className="profile-details-cardDetail p-15">
            <Alert
              style={{ alignItems: "center", width: "100%" }}
              severity="error"
            >
              No team members
            </Alert>
          </Grid>
        )}
      </Grid>
    </>
  );

  useEffect(() => {
    props.editCompany && getCompanyDetails();
    // eslint-disable-next-line
  }, [deleteControl]);

  return (
    <>
      <Preloader showPreloader={showLoader} />
      {showLoader ? (
        ""
      ) : (
        <>
          {/* impersonate user alert */}
          <Modal
            open={impersonateAlert}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={"inspection-filter-modal-main"}
          >
            <Box
              className={"inspection-filter-modal"}
              sx={{ maxWidth: "480px !important", height: "231px !important" }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <CloseIcon
                    onClick={handleCloseimpersonateAlert}
                    sx={{ float: "right", cursor: "pointer" }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <Typography
                    sx={{
                      color: "#333333",
                      fontFamily: "open sans",
                      fontSize: "22px",
                      mt: "15px",
                      letterSpacing: 0,
                    }}
                  >
                    Confirmation
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ textAlign: "center", mt: 2 }}>
                  <Typography
                    sx={{
                      display: "inline",
                      color: "#333333",
                      fontFamily: "open sans",
                      fontSize: "14px",
                      mt: "15px",
                      letterSpacing: 0,
                    }}
                  >
                    Are you sure you want to impersonate
                  </Typography>
                  <h1
                    style={{ display: "inline" }}
                  >{` ${impersonateUserData.userName}?`}</h1>
                </Grid>
                <Grid
                  container
                  sx={{ display: "flex", justifyContent: "center", mt: 5 }}
                >
                  <Button
                    variant="outlined"
                    className="inspection-filter-modal-buttons-cancel"
                    sx={{ width: "119px" }}
                    onClick={handleCloseimpersonateAlert}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    onClick={() => impersonateUser()}
                    loading={showLoader}
                    variant="contained"
                    className="inspection-filter-modal-buttons-apply"
                    sx={{ width: "119px", ml: 3 }}
                  >
                    Apply
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Modal>
          {/*delete profile image alert dialog*/}
          <Dialog
            open={showProfRemoveDialog}
            onClose={() => setShowProfRemoveDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="delete-alert-modal"
          >
            <DialogTitle id="alert-dialog-title">
              {"Delete confirmation"}
              <CloseIcon
                className="close-icon"
                onClick={() => setShowProfRemoveDialog(false)}
              />
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure to delete the profile image?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                className="delete-modal-cancel-btn"
                onClick={() => setShowProfRemoveDialog(false)}
              >
                Cancel
              </Button>
              <Button
                autoFocus
                color="error"
                variant="contained"
                className="default-btn"
                onClick={() => {
                  deleteProfileImage();
                  setImage("");
                }}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <Grid container className="teams-container">
            <Grid item xs={12} className="details-title">
              <span className="team-user-name">Company Profile</span>
              <p>
                Keep your company information up-to-date. Your clients will use
                this to get in touch with you.{" "}
              </p>
            </Grid>
            <Box style={{ width: "100%" }}>
              {/* ------------------delete alert dialog------------------------ */}
              <Dialog
                open={openAlert}
                onClose={handleCloseAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="delete-alert-modal"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Delete team member?"}
                  <CloseIcon
                    className="close-icon"
                    onClick={handleCloseAlert}
                  />
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    This action cannot be undone.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    className="delete-modal-cancel-btn"
                    onClick={handleCloseAlert}
                    id="cancel_action"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="default-btn"
                    id="delete_action"
                    variant="contained"
                    color="error"
                    onClick={() => deleteTeamMember()}
                    autoFocus
                  >
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={3}>
                  <Box sx={{ minWidth: 145, marginBottom: "28px" }}>
                    <Card variant="outlined">{card}</Card>
                  </Box>
                  <Box sx={{ minWidth: 145 }}>
                    <Card variant="outlined">{permissionCard}</Card>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Box sx={{ minWidth: 145, marginBottom: "28px" }}>
                    <Card variant="outlined">{detailsCard}</Card>
                  </Box>
                  <Box sx={{ minWidth: 145, marginBottom: "28px" }}>
                    <Card variant="outlined">{paymentCard}</Card>
                  </Box>
                  <Box sx={{ minWidth: 145, marginBottom: "28px" }}>
                    <Card variant="outlined">{CompanyDetailsCard}</Card>
                  </Box>
                  {permission && (
                    <Box sx={{ minWidth: 145, marginBottom: "28px" }}>
                      <Card variant="outlined">{teamsCard}</Card>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {/* opt out reason modal */}
          <Dialog
            open={openOptOutModal}
            onClose={handleCloseOptOutModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="opt_out_modal"
          >
            <DialogTitle
              id="alert-dialog-title"
              className="opt_out_modal_title"
            >
              <span>Opt out reasons </span>
              <CloseIcon
                onClick={handleCloseOptOutModal}
                style={{ cursor: "pointer" }}
              />
            </DialogTitle>
            <DialogContent className="rdp_app_modal_content">
              <Grid container>
                <FormGroup style={{ width: "100%" }}>
                  {masterOptOutReason?.map((value, index) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={value.id}
                            checked={optOutReasons
                              ?.map((el) => el.id)
                              .includes(value.id)}
                            onClick={(e) =>
                              handelOptOutSelections(e.target.checked, value)
                            }
                          />
                        }
                        label={value.opt_out_reason}
                      />
                    );
                  })}
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={"other"}
                        defaultChecked={
                          otherReasonVal !== null && otherReasonVal !== ""
                            ? true
                            : false
                        }
                        onClick={(e) => handleOtherReason(e)}
                      />
                    }
                    label={"Other"}
                  />
                  <div>
                    {otherReason && (
                      <TextareaAutosize
                        style={{ width: "100%", padding: "5px" }}
                        minRows={3}
                        maxRows={4}
                        placeholder="Enter your reason"
                        onChange={OtherReasonHandler}
                        value={otherReasonVal}
                      />
                    )}
                  </div>
                </FormGroup>
              </Grid>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "20px",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <span id="opt_out_error"></span>
              <Button
                autoFocus
                color="error"
                variant="contained"
                className="default-btn"
                onClick={validateOptOut}
              >
                Done
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

export default PTCompanyDetailsView;
