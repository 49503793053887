import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import FroalaEditorComponent from "../htmlEditor/FroalaEditorComponent";
import { filterSelectTagIntro } from "../../components/PTadmin/PtTemplate/PtTemplateHelperFunctions";
import { filterObjectsUptoCategory, timestamp } from "../../utils/services";

const froalaConfig = {
  imagePaste: false,
  spellCheck: true,
  heightMin: 145,
  heightMax: 250,
  toolbarButtons: {
    moreText: { buttons: ["bold", "italic", "underline", "fontFamily", "fontSize", "textColor", "backgroundColor", "alignLeft", "alignCenter", "alignRight"], align: "left", buttonsVisible: 3 },
    moreRich: { buttons: ["insertLink", "emoticons", "specialCharacters", "fontAwesome", "formatOLSimple", "formatUL", "outdent", "indent"], align: "left", buttonsVisible: 0 },
    moreMisc: { buttons: ["undo", "redo"], align: "right", buttonsVisible: 2 },
  },
  pluginsEnabled: ["wordPaste", "spellChecker", "codeBeautifier", "fontFamily", "fontSize", "colors", "align", "lists"],
};

const InlineIntroductoryText = ({ from, isActive, setIsActive, disableDnD, selectedID, accordOpen, AllCategoryList, categoryId, categoryUuid, updateTemplate }) => {
  const categoryData = AllCategoryList?.template?.categories?.find((category) => category.uuid === categoryUuid);
  const editor = useRef();
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.templates)?.categoryList;
  const [introductory, setIntroductory] = useState({ innerHTML: categoryData?.introductory_text?.replace(/<[^>]+>\s*<\/[^>]+>/g, "") });
  const [introductoryDropdowns, setIntroductoryDropdowns] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);

  const BorderRadius = (from) => {
    if (from?.split("_")[0] !== "SUBCATEGORY") {
      return "4px";
    }
  };
  const borderBottomAccord = (from, isOpen) => {
    if (from?.split("_")[0] === "SUBCATEGORY" && isOpen) {
      return "none !important";
    } else if (from?.split("_")[0] === "SUBCATEGORY" && !isOpen) {
      return "1px solid #e7e7e9 ";
    }
  };
  const isShadowing = (from) => {
    if (from?.split("_")[0] === "CATEGORY" && from?.split("_")[0] === "details") {
      return "-5px 6px 8px 1px #e1e1e1";
    } else if (from?.split("_")[0] === "CATEGORY" || from?.split("_")[0] === "details") {
      return "-5px 6px 8px 1px #e1e1e1";
    } else {
      return "none";
    }
  };

  const handleEditorChange = (name, content) => {
    setIntroductory({ innerHTML: content });
    const catList = AllCategoryList?.template?.categories;
    const categoryIndex = catList.findIndex((cat) => cat.uuid === categoryUuid);
    catList[categoryIndex].introductory_text = filterSelectTagIntro(content);
    catList[categoryIndex].introductory_text_dropdowns = introductoryDropdowns;
    catList[categoryIndex].updated_at = timestamp();
    setHasChanges(true);
    filterObjectsUptoCategory(catList, categoryId, dispatch, categories);
    updateTemplate("noToast");
  };

  const handleUpdateTemplateData = (name, value) => {
    const catList = AllCategoryList?.template?.categories;
    const categoryIndex = catList.findIndex((cat) => cat.uuid === categoryUuid);
    catList[categoryIndex].introductory_text = filterSelectTagIntro(value);
    catList[categoryIndex].introductory_text_dropdowns = introductoryDropdowns;
    catList[categoryIndex].updated_at = timestamp();
    setHasChanges(false);
    filterObjectsUptoCategory(catList, categoryId, dispatch, categories);
    updateTemplate("noToast");
  };

  const handleChangeDropdown = (event, dropdownUuid, type, commentUUID, commentDisabled) => {
    const selectedValue = event.target.value;
    let textDropdowns = categoryData.introductory_text_dropdowns;
    let textDropdownIndex = categoryData.introductory_text_dropdowns.findIndex((dropdown) => dropdown.dropdown_uuid === dropdownUuid);
    textDropdowns[textDropdownIndex] = { ...textDropdowns[textDropdownIndex], selected_value: selectedValue };
    categoryData.updated_at = new Date().toISOString();
    setIntroductoryDropdowns(textDropdowns);
    setHasChanges(false);
    filterObjectsUptoCategory([categoryData], categoryId, dispatch, categories);
    updateTemplate("noToast");
  };

  const rebindDropdownListeners = () => {
    let introDropElements = document.querySelectorAll(".intro-cl-dropdown");
    introDropElements.forEach((element) => {
      const dropType = element.getAttribute("dropType");
      const commentUUID = element.getAttribute("comment_uuid");
      const commentDisabled = element.getAttribute("disabled");
      if (element) {
        element.onchange = (event) => handleChangeDropdown(event, element.id, "intro", commentUUID, commentDisabled);
      }
    });
  };

  rebindDropdownListeners();

  useEffect(() => {
    const introductoryEditorData = introductory.current;
    introductoryEditorData?.events?.on("contentChanged", () => {
      rebindDropdownListeners();
    });
    rebindDropdownListeners();
    return () => {
      introductoryEditorData?.events?.off("contentChanged");
    };
  }, [AllCategoryList]);

  return (
    <>
      <div style={{ cursor: "auto" }}>
        <div
          onClick={(event) => {
            event.stopPropagation();
            setIsActive(!isActive);
            disableDnD && disableDnD(!isActive, selectedID);
          }}
          style={{
            borderRadius: BorderRadius(from),
            boxShadow: isShadowing(from),
            position: "relative",
            zIndex: isActive && "0",
            borderBottom: borderBottomAccord(from, accordOpen),
            padding: "10px",
            cursor: "pointer",
            height: "51px",
            alignContent: "center"
          }}
          className="accordion"
        >
          <div
            className="accordion__title"
            style={{
              color: isActive ? "#4282E2" : "#333333",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: "56px"
            }}
          >
            <div
              style={{
                fontSize: "14px",
                fontWeight: "400",
                paddingLeft: "8px",
              }}
            >
              Introductory Text
            </div>
            {isActive ? (
              <ExpandLessIcon sx={{ color: "#4282E2" }} />
            ) : (
              <ExpandMoreIcon sx={{ color: "#333333" }} />
            )}
          </div>
        </div>
        {isActive && (
          <div style={{ padding: "32px", borderRadius: "0 0 8px 8px" }}>
            <Grid container display="flex">
              <FroalaEditorComponent
                editor={editor}
                id={"introTextTE"}
                commentData={categoryData}
                onChange={handleEditorChange}
                editorData={introductory}
                froalaConfig={froalaConfig}
                onBlur={handleUpdateTemplateData}
                minHeight={201}
                maxHeight={300}
              />
            </Grid>
          </div>
        )}
      </div>
    </>
  );
};

export default InlineIntroductoryText;
