import { API_CONSTANTS, API_METHODS } from '../config/api-constants';
import { fetchCall } from './ajax';

// post methord call
export const apiCall = (callback, payload,end_point_key,type) => {
    const url = `${API_CONSTANTS[end_point_key]}`;
    const method = API_METHODS.POST;
    return fetchCall(response => callback(response),
      url,
      method,
      payload,
      type
    );
  };
// for pagination
  export const apiCallPaginated = (callback, payload,end_point_key,pageCount) => {
    const url = `${API_CONSTANTS[end_point_key]}?page=${pageCount}`;
    const method = API_METHODS.POST;
    return fetchCall(response => callback(response),
      url,
      method,
      payload
    );
  };

// get method call
export const apiCallGet = (callback, payload,end_point_key) => {
    const url = `${API_CONSTANTS[end_point_key]}`;
    const method = API_METHODS.GET;
    return fetchCall(response => callback(response),
      url,
      method,
      payload
    );
  };