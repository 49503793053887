import { combineReducers } from "redux";
import commentLibrary from "./CommentLibraryReducer";
import templates from "./TemplateReducer";

const createReducer = (asyncReducers) => {
  const appReducer = combineReducers({
    commentLibrary,
    templates,
    ...asyncReducers,
  });
  return (state, action) => {
    return appReducer(state, action);
  };
};

export default createReducer;
