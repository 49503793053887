import { Grid, Typography } from "@mui/material";
import CoverImage from "../../../../assets/images/report-preview-cover.png";

const ReportPreviewPages = (props) => {
  const { selectedColor, defaultLogo, useCompanyName, companyName, image } = props;
  const toc = [
    { title: "Inspection Details", number: 2 },
    { title: "Definitions", number: 3 },
    { title: "Lots and Grounds", number: 5 },
    { title: "Exterior", number: 6 },
    { title: "Roof", number: 7 },
    { title: "Garage", number: 8 },
    { title: "Electrical", number: 9 },
    { title: "Attic", number: 10 },
    { title: "Structure", number: 10 },
    { title: "Basement", number: 11 },
    { title: "Air Conditioning", number: 12 },
    { title: "Fireplace/ Wood Stove", number: 13 },
    { title: "Plumbing", number: 13 },
    { title: "Bedroom", number: 15 },
    { title: "Kitchen", number: 16 },
    { title: "Living Space", number: 17 },
    { title: "Plumbing", number: 18 },
    { title: "Laundry Room/Area", number: 19 },
  ];
  return (
    <Grid container>
      <Grid item xs={12} md={6} className="preview-container-left">
        <div className="preview-page-container">
          <Grid container>
            <Grid item xs={4}>
              {useCompanyName ? (
                <Typography className="report-usenameaslogo">{companyName}</Typography>
              ) : (
                <>
                  {image && <img alt="prv" src={typeof image === "string" ? image : URL.createObjectURL(image)} className="report-preview-logo" />}{" "}
                  {!image && defaultLogo ? <img alt="dpp" src={defaultLogo} className="report-preview-logo" /> : <div className="report-preview-logo"></div>}
                </>
              )}
            </Grid>
            <Grid item xs={8}>
              <div className="report-divider-cover" style={{ backgroundColor: selectedColor }}></div>
            </Grid>
            <Grid item xs={12} className="cover-date-container">
              <Typography className="cover-date">May 20, 2022</Typography>
              <Typography className="cover-address">1234 Something Street, San Francisco, US 12345</Typography>
              <Typography className="cover-title">Home Inspection Report</Typography>
              <Grid container>
                <Grid item>
                  <Typography className="cover-detail-title">PREPARED FOR:</Typography>
                  <Typography className="cover-detail-value">John Client</Typography>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: "50px" }}>
                  <Typography className="cover-detail-title">INSPECTED BY:</Typography>
                  <Typography className="cover-detail-value">Will Singer/Triple A Inspection</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <img alt="imaaage" src={CoverImage} className="report-preview-cover-image"></img>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12} md={6} className="preview-container-right">
        <div className="preview-page-container">
          <Grid container className="toc-container">
            <Grid item xs={6}>
              {useCompanyName ? (
                <Typography className="toc-usenameaslogo">{companyName}</Typography>
              ) : (
                <>
                  {image && <img alt="prr" src={typeof image === "string" ? image : URL.createObjectURL(image)} className="report-company-image" />}{" "}
                  {!image && defaultLogo ? <img alt="preview" src={defaultLogo} className="report-company-image" /> : <div className="report-company-image"></div>}
                </>
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography className="toc-header-right">1234 Something Street</Typography>
              <Typography className="toc-header-right-client">Prepared for: John Client</Typography>
            </Grid>
            <Grid item xs={12}>
              <div className="toc-title" style={{ backgroundColor: selectedColor }}>
                Table of Contents
              </div>
            </Grid>
            <Grid item xs={12} className="table-container">
              <Grid container>
                {toc.map((value, index) => {
                  return (
                    <>
                      <Grid item xs={6} key={index}>
                        {value.title}
                      </Grid>
                      <Grid item xs={6} className="table-number">
                        {value.number}
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={6} className="footer-left">
              Copyright © 1998-2024. Palmtech
            </Grid>
            <Grid item xs={6} className="footer-right">
              1/25
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default ReportPreviewPages;
