import { Grid, Typography } from '@mui/material'
import PdfFormFill from './PdfFormFill'
import PdfFormFillActions from './PdfFormFillActions'
import SettingsIcon from "@mui/icons-material/Settings";
import useMediaQuery from '@mui/material/useMediaQuery';
import AddCategoryButton from './Elements/AddCategoryButton';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { useLocation } from 'react-router-dom';
import PtCreateNewTemplateModal from './Modals/PtCreateNewTemplateModal';
import PtDeleteTemplateModal from './Modals/PtDeleteTemplateModal';
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useEffect, useState } from 'react';

const PtTemplateEditor = ({templateState,setTemplateState,updatePtTempateData,getTemplateData}) => {
  const [openNewTempModal,setOpenNewTempModal] = useState(false);
  const [openDeleteModal,setOpenDeleteModal] = useState(false);
  const [editTemplate,setEditTemplate] = useState(false);
  const [refresh, setRefresh] = useState(false)
  const [noLoader,setNoloader] = useState(false)
  const [reordered,setReordered] = useState(false);
  const location = useLocation()
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isMobileOrTab = useMediaQuery('(max-width: 900px)');
  let inactivityTimer;
  
  const handleCloseNewTempModal =()=>{
    setOpenNewTempModal(false)
    setEditTemplate(false)
  }
  const handleCloseDeleteModal =()=>{
    setOpenDeleteModal(false)
  }
  const handleMouseOut = () => {
    reordered && updatePtTempateData(templateState?.templateData?.data?.template)
    setReordered(false)
  };

  const onDragEndHandle = (result) => {
    if (!result.destination) {
      return;
    }    
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
  
    if (sourceIndex === destinationIndex) {
      return;
    }  
    const newState = { ...templateState };
    const categories = [...newState.templateData.data.template.pdf_categories];
    const [movedCategory] = categories.splice(sourceIndex, 1);
    categories.splice(destinationIndex, 0, movedCategory);
    categories.forEach((category, index) => {
      category.order = index + 1;
    });
    newState.templateData.data.template.pdf_categories = categories;
    setTemplateState(newState);
    setReordered(true);
  };

  useEffect(() => {
    const startInactivityTimer = () => {
      clearTimeout(inactivityTimer);    
      inactivityTimer = setTimeout(() => {
        reordered && updatePtTempateData(templateState?.templateDataCopy?.data?.template,'no toast');
        setReordered(false);
      }, 5000);
    };
    startInactivityTimer();
    window.addEventListener('mousemove', startInactivityTimer);
    window.addEventListener('keydown', startInactivityTimer);
    return () => {
      clearTimeout(inactivityTimer);
      window.removeEventListener('mousemove', startInactivityTimer);
      window.removeEventListener('keydown', startInactivityTimer);
    };
  }, [templateState]);
  
  return (
    <>
    <PtDeleteTemplateModal handleCloseDeleteModal={handleCloseDeleteModal} openDeleteModal={openDeleteModal} 
    selectedTemplateName={location.state.name} isPdfTemplateDelete={location.state.isPdfTemplate}
    deleteTemplateGuid={location.state.guid} setRefresh={setRefresh} setNoloader={setNoloader} editTemplate={editTemplate}/>
    <PtCreateNewTemplateModal setOpenDeleteModal={setOpenDeleteModal} openNewTempModal={openNewTempModal} handleCloseNewTempModal={handleCloseNewTempModal} templateState={templateState} editTemplate={editTemplate} guid={location?.state?.guid} getTemplateData={getTemplateData}/>
      <Grid container className={!isMobileOrTab?"PtTemplateEditor_wrapper":"PtTemplateEditor_wrapper_sm"} draggable="false" spacing={4}>
          {/* <Preloader showPreloader={showLoader} /> */}
          <Grid item sm={12} md={8.5} style={{marginTop : isMobile && '25px',minHeight:'100vh'}} onMouseLeave={handleMouseOut}>
          <Grid  container className="templates pt__templates">
            <Grid item xs={11}>
              {/* <Typography className="layout__title">{templateDetails?.name?.length > 50 ? `${templateDetails?.name?.substring(50, 0)}...` : templateDetails?.name} </Typography> */}
                 <Typography className="pt_layout__title"><span>{location?.state?.isPdfTemplate === 1?<PictureAsPdfOutlinedIcon className="pdf_icon_details"/>:''}{templateState?.templateData?.data?.template?.name}</span> </Typography>
            </Grid>
            <Grid item xs={1}>
              <SettingsIcon id="template_setting_btn"  className="templates__icon" sx={{ float: "right" }} 
               onClick={templateState.roleId !== 2 && templateState.roleId !== 3
                  ? null
                  : () => {
                      setOpenNewTempModal(true);
                      setEditTemplate(true);
                    }
              }
              />
            </Grid>
            <Grid item xs={12}>
              {/* <p className="layout__description">{templateDetails.description ?? ""}</p> */}
              <p className="pt_layout__description">{templateState?.templateData?.data?.template?.description ?? ""}</p>
            </Grid>
          </Grid>
          <DragDropContext onDragEnd={(result) => {onDragEndHandle(result)}}>
            <Droppable droppableId="categoryDropArea">
                {(provided) => (
                  <div className='pt_dropable' ref={provided.innerRef}>
                    {templateState?.templateData?.data?.template?.pdf_categories?.map((category,catIndex)=>(
                      !category?.is_deleted? 
                          <PdfFormFill {...category}  key={category.uuid} templateState={templateState} catIndex={catIndex} setTemplateState={setTemplateState} updatePtTempateData={updatePtTempateData}/> 
                      :''))
                    }
             {provided.placeholder}
            </div>
            )}
            </Droppable>         
          <AddCategoryButton templateState={templateState} setTemplateState={setTemplateState} updatePtTempateData={updatePtTempateData}/>
          </DragDropContext>
          </Grid>
          <Grid item sm={12} md={3.5} >
            <PdfFormFillActions  templateState={templateState} setTemplateState={setTemplateState} updatePtTempateData={updatePtTempateData}/>
          </Grid>
        </Grid>
    </>
  )
}

export default PtTemplateEditor