import { FormControl, Grid, TextField, Menu, MenuItem } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useState, useEffect } from "react";
import { Collapse } from "@mui/material";
import InputField from "./InputField";
import MediaUpload from "./MediaUpload";
import AddElementButton from "./AddElementButton";
import Fade from "@mui/material/Fade";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import ExpandingCheckbox from "./ExpandingCheckbox";
import InformationalText from "./InformationalText";
import CheckBoxElement from "./CheckBoxElement";
import TableElement from "./TableElement";
import Signature from "./Signature";
import DividerElement from "./DividerElement";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { deleteElement, findParentUUID, innerElCount, updateElementJsonByUUID, updateJSON } from "../PtTemplateHelperFunctions";
import { debounce } from "lodash";
import RadioElement from "./RadioElement";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const GroupElement = ({
  nestingLevel = 0,
  uuid,
  parentType,
  elemParentType,
  elements,
  catuuid,
  templateState,
  setTemplateState,
  updatePtTempateData,
  label,
  merge_id,
  isDragging,
  catType,
  setDragDisable,
}) => {
  const [expanded, setExpanded] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [groupName, setGroupName] = useState("");
  const [groupTag, setGroupTag] = useState("");
  const [groupEdited, setGroupEdited] = useState("");
  const [editedInpMerge, setEditedInpMerge] = useState(false);
  const [active, setActive] = useState(false);
  const [mergeIdError, setMergeIdError] = useState(false);
  const [reorderedGroupEL, setReorderedGroupEL] = useState(false);
  const handleExpanded = () => setExpanded(!expanded);
  const open = Boolean(anchorEl);
  const handleClickMore = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // Create a debounced function to update the state
  const debouncedUpdateData = debounce((prevData) => {
    setTemplateState((prev) => ({ ...prev, templateData: prevData }));
  }, 2000); // Delay state update by 2 seconds
  const handleChangeGroupName = (e, changeType) => {
    e.persist();
    let updValue = e.target.value;
    let updatedData;
    let prevData = JSON.parse(JSON.stringify(templateState?.templateDataCopy));
    if (changeType === "label") {
      setGroupEdited(true);
      setGroupName(updValue);
      updatedData = updateElementJsonByUUID(templateState?.templateDataCopy.data, uuid, updValue, "label");
    } else if (changeType === "tag") {
      setGroupTag(updValue);
      setEditedInpMerge(true);
      if (templateState?.mergeIdArray.includes(updValue)) {
        setMergeIdError(true);
        updatedData = updateElementJsonByUUID(templateState?.templateDataCopy.data, uuid, updValue, "tag");
      } else {
        setMergeIdError(false);
        updatedData = updateElementJsonByUUID(templateState?.templateDataCopy.data, uuid, updValue, "tag");
      }
    }
    if (updatedData) {
      prevData.data = updatedData;
      debouncedUpdateData(prevData);
    }
  };

  const clickAwaySaveptData = () => {
    let prevData = JSON.parse(JSON.stringify(templateState?.templateData));
    let catIdx = prevData?.data?.template?.pdf_categories.findIndex((el) => el.uuid === catuuid);
    updateJSON(prevData, uuid, groupName, "label");
    let editedCategory = [prevData.data.template.pdf_categories[catIdx]];
    let newUpdatedSpecificData = JSON.parse(JSON.stringify(prevData));
    newUpdatedSpecificData.data.template.pdf_categories = editedCategory;
    setTemplateState((prev) => ({ ...prev, templateData: prevData }));
    updatePtTempateData(newUpdatedSpecificData?.data?.template);
    setGroupEdited(false);
  };

  // const clickAwaySaveptMergeData=()=>{
  //   if(mergeIdError) {
  //     setGroupTag('')
  //     let updatedData = updateElementJsonByUUID(templateState?.templateDataCopy.data, uuid, "",'tag');
  //     let prevData = JSON.parse(JSON.stringify(templateState?.templateData));
  //     prevData.data = updatedData;
  //     setTemplateState(prev=>({...prev,templateData:prevData}))
  //     setMergeIdError(false)
  //     setEditedInpMerge(false)
  //   }
  //   else{
  //   let prevData = JSON.parse(JSON.stringify(templateState?.templateData));
  //   let catIdx = prevData?.data?.template?.pdf_categories.findIndex(el=>el.uuid===catuuid)
  //   updateJSON(prevData, uuid, groupTag,"tag");
  //   let editedCategory = [prevData.data.template.pdf_categories[catIdx]];
  //   let newUpdatedSpecificData = JSON.parse(JSON.stringify(prevData));
  //   newUpdatedSpecificData.data.template.pdf_categories = editedCategory
  //   setTemplateState(prev=>({...prev,templateData:prevData}))
  //   updatePtTempateData(newUpdatedSpecificData?.data?.template)
  //   setEditedInpMerge(false)
  //   }
  // }

  const onDragEndHandleExp = (result, expUuid) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    const newState = { ...templateState };
    let prevData = JSON.parse(JSON.stringify(templateState?.templateData));
    let catIdx = prevData?.data?.template?.pdf_categories.findIndex((el) => el.uuid === catuuid);
    if (catIdx !== -1) {
      let uuidObject;
      let elemIdx;
      if (nestingLevel > 0) {
        const parentUUID = findParentUUID(templateState?.templateDataCopy.data.template, expUuid);
        const parentIndex = newState.templateData.data.template.pdf_categories[catIdx].elements.findIndex((element) => element.uuid === parentUUID);
        uuidObject = newState.templateData.data.template.pdf_categories[catIdx].elements[parentIndex].elements.find((element) => element.uuid === expUuid);
        elemIdx = newState.templateData.data.template.pdf_categories[catIdx].elements[parentIndex].elements.findIndex((element) => element.uuid === expUuid);
      } else {
        uuidObject = newState.templateData.data.template.pdf_categories[catIdx].elements.find((element) => element.uuid === expUuid);
        elemIdx = newState.templateData.data.template.pdf_categories[catIdx].elements.findIndex((element) => element.uuid === expUuid);
      }

      if (!uuidObject) {
        return;
      }
      const elementsArray = Array.from(uuidObject.elements);
      elementsArray.splice(source.index, 1);
      elementsArray.splice(destination.index, 0, uuidObject.elements[source.index]);
      uuidObject.elements = elementsArray;
      if (nestingLevel > 0) {
        const parentUUID = findParentUUID(templateState?.templateDataCopy.data.template, expUuid);
        const parentIndex = newState.templateData.data.template.pdf_categories[catIdx].elements.findIndex((element) => element.uuid === parentUUID);
        newState.templateData.data.template.pdf_categories[catIdx].elements[parentIndex].elements[elemIdx].elements = elementsArray;
        setTemplateState(newState);
        setReorderedGroupEL(true);
      } else {
        newState.templateData.data.template.pdf_categories[catIdx].elements[elemIdx].elements = elementsArray;
        setTemplateState(newState);
        setReorderedGroupEL(true);
      }
    }
  };
  const handleMouseOutExpEl = () => {
    reorderedGroupEL && updatePtTempateData(templateState?.templateData?.data?.template);
    setReorderedGroupEL(false);
  };

  const activateDragIn = () => {
    setDragDisable(true);
    if (nestingLevel > 1) {
      sessionStorage.setItem("firstLevelDrag", true);
    }
  };
  const activateDragOut = () => {
    setDragDisable(false);
    if (nestingLevel > 1) {
      sessionStorage.setItem("firstLevelDrag", false);
    }
  };

  useEffect(() => {
    setGroupName(label);
    // setGroupTag(merge_id)
  }, [templateState]);

  return (
    <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={() => active && setActive(false)}>
      <Grid
        container
        className="pt_card_border pt_inputfield"
        key={uuid}
        onClick={() => setActive(true)}
        onMouseEnter={() => (nestingLevel > 0 ? activateDragIn() : null)}
        onMouseLeave={() => (nestingLevel > 0 ? activateDragOut() : null)}>
        <div className={`${isDragging || active ? "pt_ip_drag_section pt_elem_dragging" : "pt_ip_drag_section"} `}>
          <DragIndicatorIcon className="pt_icon_color" />
        </div>
        <div className="pt_ip_content" style={{ maxWidth: "94%" }}>
          <div className="pt_ip_data">
            <div className="pt_ip_data_row1 pt_is_flex_align_center pt_space_between pointer" onClick={handleExpanded}>
              <div className="pt_is_flex_align_center gap5">
                <ContentCopyIcon style={{ transform: "rotate(-90deg)" }} />
                <span className="pt_element_name">Group</span>
              </div>
              <span className="pt_elem_count_span">
                <span>
                  {innerElCount(elements)} {innerElCount(elements) !== 1 ? "Form Elements" : "Form Element"}
                </span>
                <ExpandMoreIcon className={`pt_ta_exp_checkbox_expand_icon ${expanded ? "accord_active" : "accord_inactive"} `} />
              </span>
              {/* <ExpandMoreIcon className={`pt_ta_exp_checkbox_expand_icon `}/>    */}
            </div>
            <Grid container style={{ marginBottom: "14px" }}>
              {/* <Grid item xs={12} className="pt_merge_field">
                    <label className="pt_form_label" htmlFor="pt_group_label_merge" id="pt_group_label">
                    merge field id
                    </label>
                    <ClickAwayListener  mouseEvent="onMouseDown"
                          touchEvent="onTouchStart" onClickAway={() => editedInpMerge && clickAwaySaveptMergeData()}>
                    <TextField
                      style={{width:'50%',display:'block',background:'#fff',borderColor: mergeIdError ? '#d32f2f' : '#ced4da'}}
                      type="text"
                      id="pt_group_label_merge"
                      size="small"
                      name="pt_group_label_merge"
                      variant="outlined"
                      disabled={templateState.roleId !== 2 && templateState.roleId !== 3}
                      value={groupTag}
                      error={mergeIdError}
                      helperText={`${mergeIdError ? "This merge ID is in use."  : ""}`}
                      onChange={(e)=>handleChangeGroupName(e,'tag')}
                      placeholder="ID tag"
                      className={mergeIdError?`input-textfield pt_focussed`:"input-textfield"}/>
                      </ClickAwayListener>
                  </Grid> */}
              <Grid item xs={12}>
                <FormControl variant="standard" className="pt_w_100">
                  <label className="pt_form_label" htmlFor="pt_group" id="pt_group">
                    Label
                  </label>
                  <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={() => groupEdited && clickAwaySaveptData()}>
                    <TextField
                      fullWidth
                      type="text"
                      id="pt_group"
                      size="small"
                      name="city"
                      variant="outlined"
                      disabled={templateState.roleId !== 2 && templateState.roleId !== 3}
                      value={groupName === null ? "" : groupName}
                      onChange={(e) => handleChangeGroupName(e, "label")}
                      placeholder="Input title"
                      className="input-textfield"
                    />
                  </ClickAwayListener>
                </FormControl>
              </Grid>
            </Grid>
            <div onMouseLeave={handleMouseOutExpEl}>
              <Collapse in={expanded}>
                <DragDropContext
                  onDragEnd={(result) => {
                    onDragEndHandleExp(result, uuid);
                  }}>
                  <Droppable droppableId="grpElementDropableArea">
                    {(provided) => (
                      <div ref={provided.innerRef}>
                        {elements.length !== 0 &&
                          elements?.map((innerEl, index) => (
                            <Draggable
                              draggableId={String(innerEl.uuid)}
                              key={innerEl.uuid}
                              index={index}
                              isDragDisabled={
                                (templateState.roleId !== 2 && templateState.roleId !== 3) ||
                                nestingLevel > 1 ||
                                (nestingLevel === 0 && sessionStorage.getItem("firstLevelDrag") === "true") ||
                                sessionStorage.getItem("radioOptionHover") === "true"
                              }>
                              {(provided, snapshot) => (
                                <div {...provided.dragHandleProps} ref={provided.innerRef} {...provided.draggableProps}>
                                  {innerEl.type === "Layout/ExpandingCheckBox" ? (
                                    <div key={index}>
                                      <ExpandingCheckbox
                                        {...innerEl}
                                        parentType="expandingCheckBox"
                                        catuuid={catuuid}
                                        templateState={templateState}
                                        setTemplateState={setTemplateState}
                                        updatePtTempateData={updatePtTempateData}
                                        elemuuid={uuid}
                                        catType={catType}
                                        nestingLevel={nestingLevel + 1}
                                        setDragDisable={setDragDisable}
                                        isDragging={snapshot.isDragging}
                                      />
                                    </div>
                                  ) : innerEl.type.includes("Input") ? (
                                    <div key={index}>
                                      <InputField
                                        {...innerEl}
                                        parentType="expandingCheckBox"
                                        catuuid={catuuid}
                                        templateState={templateState}
                                        setTemplateState={setTemplateState}
                                        updatePtTempateData={updatePtTempateData}
                                        setDragDisable={setDragDisable}
                                        isDragging={snapshot.isDragging}
                                        nestingLevel={nestingLevel}
                                      />
                                    </div>
                                  ) : innerEl.type.includes("InformationText") ? (
                                    <div key={index}>
                                      <InformationalText
                                        {...innerEl}
                                        parentType="expandingCheckBox"
                                        catuuid={catuuid}
                                        templateState={templateState}
                                        setTemplateState={setTemplateState}
                                        updatePtTempateData={updatePtTempateData}
                                        setDragDisable={setDragDisable}
                                        isDragging={snapshot.isDragging}
                                        nestingLevel={nestingLevel}
                                      />
                                    </div>
                                  ) : innerEl.type === "Layout/Group" ? (
                                    <GroupElement
                                      {...innerEl}
                                      parentType="expandingCheckBox"
                                      catuuid={catuuid}
                                      templateState={templateState}
                                      setTemplateState={setTemplateState}
                                      updatePtTempateData={updatePtTempateData}
                                      elemuuid={uuid}
                                      nestingLevel={nestingLevel + 1}
                                      catType={catType}
                                      setDragDisable={setDragDisable}
                                      isDragging={snapshot.isDragging}
                                    />
                                  ) : innerEl.type === "CheckBox" ? (
                                    <div key={index}>
                                      <CheckBoxElement
                                        {...innerEl}
                                        parentType="expandingCheckBox"
                                        catuuid={catuuid}
                                        templateState={templateState}
                                        setTemplateState={setTemplateState}
                                        updatePtTempateData={updatePtTempateData}
                                        setDragDisable={setDragDisable}
                                        isDragging={snapshot.isDragging}
                                        nestingLevel={nestingLevel}
                                      />
                                    </div>
                                  ) : innerEl.type === "Signature/Any" ? (
                                    <Signature
                                      {...innerEl}
                                      parentType="expandingCheckBox"
                                      catuuid={catuuid}
                                      templateState={templateState}
                                      setTemplateState={setTemplateState}
                                      updatePtTempateData={updatePtTempateData}
                                      setDragDisable={setDragDisable}
                                      isDragging={snapshot.isDragging}
                                      nestingLevel={nestingLevel}
                                    />
                                  ) : innerEl.type === "MediaUploader" ? (
                                    <MediaUpload
                                      {...innerEl}
                                      parentType="expandingCheckBox"
                                      catuuid={catuuid}
                                      templateState={templateState}
                                      setTemplateState={setTemplateState}
                                      updatePtTempateData={updatePtTempateData}
                                      setDragDisable={setDragDisable}
                                      isDragging={snapshot.isDragging}
                                      nestingLevel={nestingLevel}
                                    />
                                  ) : innerEl.type === "Divider" ? (
                                    <DividerElement
                                      {...innerEl}
                                      parentType="expandingCheckBox"
                                      catuuid={catuuid}
                                      templateState={templateState}
                                      setTemplateState={setTemplateState}
                                      updatePtTempateData={updatePtTempateData}
                                      setDragDisable={setDragDisable}
                                      isDragging={snapshot.isDragging}
                                      nestingLevel={nestingLevel}
                                    />
                                  ) : innerEl.type === "Radio" ? (
                                    <RadioElement
                                      {...innerEl}
                                      parentType="expandingCheckBox"
                                      catuuid={catuuid}
                                      templateState={templateState}
                                      setTemplateState={setTemplateState}
                                      updatePtTempateData={updatePtTempateData}
                                      setDragDisable={setDragDisable}
                                      isDragging={snapshot.isDragging}
                                      nestingLevel={nestingLevel}
                                    />
                                  ) : innerEl.type === "Layout/Table" ? (
                                    <TableElement
                                      {...innerEl}
                                      parentType="expandingCheckBox"
                                      catuuid={catuuid}
                                      templateState={templateState}
                                      setTemplateState={setTemplateState}
                                      updatePtTempateData={updatePtTempateData}
                                      setDragDisable={setDragDisable}
                                      isDragging={snapshot.isDragging}
                                      nestingLevel={nestingLevel}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Collapse>
            </div>

            <AddElementButton
              setActive={setActive}
              elemParentType={elemParentType}
              catuuid={catuuid}
              templateState={templateState}
              setTemplateState={setTemplateState}
              updatePtTempateData={updatePtTempateData}
              elemuuid={uuid}
              catType={catType}
              parentType={parentType}
            />
          </div>
          <div className={`${templateState.roleId !== 2 && templateState.roleId !== 3 ? "pt_ip_action pt_ip_disabled" : "pt_ip_action pt_ip_enabled"}`}>
            <MoreHorizIcon style={{ marginTop: "5px" }} className="pt_icon_color" onClick={templateState.roleId !== 2 && templateState.roleId !== 3 ? null : handleClickMore} />
            <Menu
              id="fade-menu"
              MenuListProps={{ "aria-labelledby": "fade-button" }}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
              className="pt_more_menu">
              <span>
                <CloseIcon onClick={handleClose} />
              </span>
              <MenuItem onClick={() => deleteElement(templateState, setTemplateState, parentType, uuid, catuuid, updatePtTempateData, handleClose)}>
                <DeleteIcon />
                Delete
              </MenuItem>
            </Menu>
          </div>
        </div>
      </Grid>
    </ClickAwayListener>
  );
};

export default GroupElement;
