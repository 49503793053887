import { API_CONSTANTS, postApiCall } from "../../config/api-constants";
export const UPDATE_TE_DATA = "UPDATE_TE_DATA";
export const UPDATE_CATEGORY_DATA = "UPDATE_CATEGORY_DATA";

export const updateTEData = (updatedData) => {
  return { type: UPDATE_TE_DATA, payload: updatedData || [] };
};

export const updateCategoryData = (updatedData) => {
  return { type: UPDATE_CATEGORY_DATA, payload: updatedData || [] };
};
